import React from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ContactForm from '../Contact/contact_form';

function ContactsScreen(props) {
 
  return (
    <div>
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default ContactsScreen;