import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import { RiAdvertisementFill } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import Logout from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { Lock } from '@mui/icons-material';
import Profile from './Profile';
import AccountSettings from '../settings/AccountSettings';
import { useNavigate } from "react-router";
import "./Header.css";
import { useAuth } from "../../Auth/AuthContext copy";
import Loogin from "../Login/Login"
import LogoMain from "../../assets/logoapp";

function Header() {

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { currentUser, setModal, logout, setAlert } = useAuth();
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };


  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      setAlert({
        isAlert: true,
        severity: 'error',
        message: error.message,
        timeout: 8000,
        location: 'main',
      });
      console.log(error);
    }
  };
  function search(e) {
    e.preventDefault()
    setQuery(e.target.value)
  }
  const re = () => {
    window.location.reload();
  }


  return (
    <div className="mainstart">
      <div className="mainChildDiv">
        <div className="brandName">
        <a href="/" title="" >
         <LogoMain className="log0height" />
          </a>
        </div>

        <div className="searbarbutton">
          <div>
            <input
              type="text"
              className="searchbar"
              placeholder="What are you looking for?"
              onChange={search}
              value={query}
            />
            <Link style={{textDecoration: 'none'}} to={{
              pathname: `/search/${query.toLowerCase()}`,

            }}>
              {" "}
              <button className="btoon">🔍</button> {" "}
            </Link>
          </div>
           
          <div className="oplastic" >
            <Link className="linkbuttons" style={{textDecoration: 'none'}} to=
              {{
                pathname: "/viewmore/Plastic",
              }}
            >
              <span>Plastic</span>
            </Link>
          </div>
          <div className="ometal" >
            <Link  className="linkbuttons" style={{textDecoration: 'none'}} to=
              {{
                pathname: "/viewmore/Metal",
              }}
            >

              <span>Metal</span>
            </Link>
          </div>
          <div className="ocopper">
            <Link  className="linkbuttons" style={{textDecoration: 'none'}} to= 
              {{
                pathname: "/viewmore/Copper",

              }}
            >
              <span>Copper</span>
            </Link>
          </div>
          <div className="osteel">
            <Link  className="linkbuttons" style={{textDecoration: 'none'}} to=
              {{ pathname: "/viewmore/Steel" }}
            >
              <span>Steel</span>
            </Link>
          </div>
          <div className="oaluminum">
          <Link  className="linkbuttons" style={{textDecoration: 'none'}} to=
                {{pathname:"/viewmore/Aluminium"}}
            >
              <span>Aluminium</span>
            </Link>
          </div>
          <div className="oother">
          <Link  className="linkbuttons" style={{textDecoration: 'none'}} to=
                {{pathname:"/viewmore/Others"}}
            >
            
              <span>Others</span>
            </Link>
          </div>
        </div>


        <div className="loginPage">
          <Box sx={{}}>
            {!currentUser ? (
              <Button style={{
                // backgroundColor:"white",
                color: "black",
                cursor: 'pointer',
                fontWeight: '600',
                fontStyle: 'normal',
                paddingTop: '15px'

              }} startIcon={<Lock />} onClick={openLogin}>
                Login
              </Button>
            ) : (
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src={currentUser?.photoURL}
                  >
                    {currentUser?.displayName?.charAt(0)?.toUpperCase() ||
                      currentUser?.email?.charAt(0)?.toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() =>
                setModal({
                  isOpen: true,
                  title: 'Update Profile',
                  content: <Profile />,
                })
              }
            >
              <Avatar src={currentUser?.photoURL} /> Profile
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                setModal({
                  isOpen: true,
                  title: 'Account Settings',
                  content: <AccountSettings />,
                })
              }
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <Link style={{textDecoration: 'none'}} to={{
              pathname: `/userpost/${currentUser?.uid}`,
            }} >
              <MenuItem style={{ color: "black" }} >
                <ListItemIcon>
                  <RiAdvertisementFill fontSize="large" />
                </ListItemIcon>
                Your Post
              </MenuItem>
            </Link>
            <Link style={{textDecoration: 'none'}} to={{
              pathname: `/contact`,
            }} >
              <MenuItem style={{ color: "black" }} >
                <ListItemIcon>
                  <IoIosContact fontSize="large" />
                </ListItemIcon>
                Help & Contact
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
        <div className="sellbuttondiv">
          <Link to="/createpost">
            {" "}
            <div className="sellbutton">Post Ad</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
