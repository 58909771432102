import React, { Fragment, useState ,useMemo } from "react";
import "./Genrating.css";
import Header from "../Header/Header";
import { Box } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { Firebase } from "../../firebase/config";
import { useAuth } from '../../Auth/AuthContext copy';
import { useNavigate } from "react-router";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { v4 as uuidv4 } from 'uuid';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Currencies from 'list-of-currencies/currencies-with-names.json'
import { MdDeleteForever } from "react-icons/md";
import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';
// import { AnimatePresence, motion } from 'framer-motion'
import { MdError } from 'react-icons/md'
const GeneratePost = () => {
  const { currentUser,setLoading } = useAuth();
  // const[curncyunit,setcurrncyunit]=useState("");
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  let [selectedImages, setSelectedImages] = useState([]);
  // let [name, setName] = useState("");
  // let [location, setlocation] = useState("");
  let [phone, setphone] = useState();
  // let [category, setCategory] = useState("");
  // let [price, setPrice] = useState("");
  // let [description, setDescription] = useState("");
  let [image, setImage] = useState([]);
 
  const [url, setUrls] = useState([]);
  // let[weight,setweight]=useState("");
  const [value, setValue] = useState('');
  const options = useMemo(() => countryList().getData(), []);
  // const [selectedUnit, setSelectedUnit] = React.useState();
  // const[databundle,setdatbundle]=useState();
  const data = {
    Weightdata: [
      {name: "Kilogram" },
      {name: "Pound"},
      { name: "Gram"},
      { name: "Tonne"},
      {name: "Metric_ton" ,}
    ]
  };
 
 
 
  const changeHandler = value => {
    setValue(value)
  }


  const onSelectFile = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
  
    const compressOptions = {
      maxSizeMB: 1, // Maximum size of the compressed image in MB
      useWebWorker: true // Use Web Worker for compression (recommended)
    };
  
    const compressedImagesArray = await Promise.all(
      selectedFilesArray.map(async (file) => {
        try {
          const compressedFile = await imageCompression(file, compressOptions);
          return compressedFile;
        } catch (error) {
          console.error('Error compressing image:', error);
          return file; // Return original file if compression fails
        }
      })
    );
  
    const imagesArray = compressedImagesArray.map((file) => {
      return URL.createObjectURL(file);
    });
  
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  
    const promises = [];
    compressedImagesArray.forEach((image) => {
      const imageName = uuidv4() + '.' + image.name;
      const uploadTask = Firebase.storage().ref(`images/${imageName}`).put(image);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await Firebase.storage()
            .ref("images")
            .child(imageName)
            .getDownloadURL()
            .then((url) => {
              setUrls((prevState) => [...prevState, url]);
            });
        }
      );
    });
  
    // FOR BUG IN CHROME
    event.target.value = "";
  };

  // const onSelectFile = (event) => {
  //   const selectedFiles = event.target.files;
  //   const selectedFilesArray = Array.from(selectedFiles);

  //   const imagesArray = selectedFilesArray.map((file) => {
  //     return URL.createObjectURL(file);
  //   });
  //   // setImage((previousImages) => previousImages.concat(selectedFilesArray));
  //   setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  //   const promises = [];
  //   selectedFilesArray.map((image) => {
  //     const imageName = uuidv4() + '.' + image.name;
  //     const uploadTask = Firebase.storage().ref(`images/${imageName}`).put(image);
  //     promises.push(uploadTask);
  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         setProgress(progress);
  //       },
  //       (error) => {
  //         console.log(error);
  //       },
  //       async () => {
  //         await Firebase.storage()
  //           .ref("images")
  //           .child(imageName)
  //           .getDownloadURL()
  //           .then((urls) => {
  //             setUrls((prevState) => [...prevState, urls]);
  //           });
  //       }
  //     );
  //   }
  //   );

  //   // FOR BUG IN CHROME
  //   event.target.value = "";
  // };

  function deleteHandler(image, index) {
    console.log(index)
    setSelectedImages(selectedImages.filter((e) => e !== image));
    setImage(selectedImages.filter((e) => e !== image));
    const fileRef = Firebase.storage().refFromURL(url[index]);
    url.splice(index, 1);
    fileRef.delete().then(
      function () {
        // File deleted successfully
        console.log("File Deleted")
        // url.splice(index, 1);

      }).catch(function (error) {
        // Some Error occurred
      });
    URL.revokeObjectURL(image);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  return (
    <Fragment>

      <Header />

      <div className="headDivin"  >
        <div className="top">
          <h2>Sell your Scrap with Easy Step </h2>
        </div>
         

        <form onSubmit={
          handleSubmit(
          (data) =>{
            setLoading(true);
            let date = new Date().toString();
            let time=new Date();
            if (Array.isArray(url) && url.length) {
              console.log("array has elemnt")
              Firebase.firestore().collection("products").add({
                time,
                name:data.name,
                category:data.category,
                price:data.price,
                countryunit:data.curncyunit,
                description:data.description,
                url,
                phone,
                location:data.location,
                weight:data.weight,
                weightunit:data.selectedUnit,
                country:value.label,
                userId: currentUser.uid,
                createdAt: date,
              }).then(() => {
                setLoading(false);
                navigate("/");
              });
            }
          } 
          )
          }>
    
        <div className="secondheading">
          <h3>Material</h3>
        </div>
        <br />
        <div className="lablesetting">
          <div className="leftlable">
         <label>Title</label>
         </div>
         <div className="requird">
         {errors.name && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
       
        <input
          {...register('name', { required: true })}
          className="input"
          type="text"
          maxLength="80"
          // name="Name"
          // value={name}
          // onChange={(e) => {
          //   setName(e.target.value);
          // }}

        />
        <br />

        <div className="lablesetting">
          <div className="leftlable">
         <label>Description</label>
         </div>
         <div className="requird">
         {errors.description && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
        <textarea
          className="inputt"
          type="text"
          maxLength="1080"
          {...register('description', { required: true })}
          // name="Description"
          // value={description}
          // onChange={(e) => {
          //   setDescription(e.target.value);
          // }}
        />
        <br />
        <div className="lablesetting">
          <div className="leftlable">
          <label>Category</label>
         </div>
         <div className="requird">
         {errors.category && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
        <select
          // name="Category"
          // onChange={(e) => {
          //   setCategory(e.target.value);
          // }}
          {...register('category', { required: true })}
          className="input"
        > <option value="" >Select Category</option>
          <option value="Metal">Metal</option>
          <option value="Plastic">Plastic</option>
          <option value="Steel">Steel</option>
          <option value="Copper">Copper</option>
          <option value="Aluminium">Aluminium</option>
          <option value="Others">Others</option>
        </select>
        <br />
       
        <div className="weiuni">
        <div className="wei">
        <div className="lablesetting">
          <div className="leftlable">
          <label>Estimated Weight</label>
         </div>
         <div className="requird">
         {errors.weight && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
        <input
          {...register('weight', { required: true })}
          className="input"
          type="number"
          // name="weight"
          // value={weight}
          // onChange={(e) => {
          //   setweight(e.target.value);
          // }}
        />
        </div>

         <div className="uni">
         <div className="lablesetting">
          <div className="leftlable">
          <label>Weight Unit</label>
         </div>
         <div className="requird">
         {errors.selectedUnit && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
      
        <select
          {...register('selectedUnit', { required: true })}
          className="input"
          placeholder="Unit"
          // value={selectedUnit}
          // onChange={(e) => setSelectedUnit(e.target.value)}
        >
          <option value="" >Choose Unit</option>
          {data.Weightdata.map((value, key) => {
            return (
              <option value={value.name} key={key}>
                {value.name}
              </option>
            );
          })}
               </select>
            </div>
            </div>

        <div className="pricecurrncy">
        <div className="pr">
        <div className="lablesetting">
          <div className="leftlable">
          <label>Price</label>
         </div>
         <div className="requird">
         {errors.price && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
      
        <input
           {...register('price', { required: true })}
          className="input"
          type="number"
          // name="Price"
          // value={price}
          // onChange={(e) => {
          //   setPrice(e.target.value);
          // }}
        />
        </div>
         <div className="curr">
         <div className="lablesetting">
          <div className="leftlable">
          <label>Currancy Unit</label>
         </div>
         <div className="requird">
         {errors.curncyunit && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
       
        <select
          {...register('curncyunit', { required: true })}
          className="input"
          placeholder="Unit"
          // value={curncyunit}
          // onChange={(e) => setcurrncyunit(e.target.value)}
        >
          <option value="">Currancy Unit</option>
          {Currencies.map((value, key) => {
            return (
              <option value={value} key={key}>
                {value}
              </option>
            );
          })}
               </select>
            </div>
            </div>
        <br />
        <div className="secondheading">
          <h3>Contact Information</h3>
        </div>
        <br />
        <div className="mobcontr">
        <div className="mob">
        <div className="lablesetting">
          <div className="leftlable">
          <label>Mobile Number</label>
         </div>
         <div className="requird">
         {errors.phonee && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
       <div>
        
       </div>
        <PhoneInput
          {...register('phonee', { required: true })}
          className="input-phone-number"
          maxLength="20"
        //  placeholder="Enter phone number"
          value={phone}
          onChange={setphone}
          />
        <br />
        </div>
        <div className="contr">
        <div className="lablesetting">
          <div className="leftlable">
          <label>Country</label>
         </div>
         <div className="requird">
         {/* {errors.setValue && (
            <div className="reqcolor"> <MdError /> Required</div>
          )} */}
         </div>
        </div>
       
        <div style={{
          marginTop:'8px'}}>
        <Select
         options={options} value={value} onChange={changeHandler} 
         className="contarydecoration"
         />
         </div>
         </div>
        </div>
        <div className="lablesetting">
          <div className="leftlable">
          <label>Addrees</label>
         </div>
         <div className="requird">
         {errors.location && (
            <div className="reqcolor"> <MdError /> Required</div>
          )}
         </div>
        </div>
        <input
          {...register('location', { required: true })}
          className="input"
          type="text"
          maxLength="200"
          // name="location"
          // value={location}
          // onChange={(e) => {
          //   setlocation(e.target.value);
          // }}
        />
        
        <br />
        <br />
        <div className="secondheading">
          <h3>Add Images</h3>
        </div>
        <br />
        <div className="lablesetting">
          <div className="leftlable">
          
         </div>
         
        </div>
        <div className="picturemenu">

          <div className="selecting">

            <label style={{ color: 'white' }}>
              + Add Images
              <br />
              <span>maximum 4 images allows</span>
              <input
                type="file"
                name="images"
                onChange={onSelectFile}
                multiple
                accept="image/png , image/jpeg, image/webp"
              />
            </label>
            <br />
           
            <div className="progbar">
             <div className="tex">
              <text>Uploading</text>
              <Box sx={backDrop}>
                {progress < 100 ? (
                  <CircularProgressWithLabel  value={progress} />
                ) : (
                  <CheckCircleOutline
                    sx={{ width: 40, height: 40, color: 'lightgreen' }}
                  />
                )}
              </Box>
              </div>
              <div className="bar">
              <progress value={progress} max="100" />
              </div>
            </div>

            <div className="images">
              {selectedImages && selectedImages.map((image, index) => {
                return (
                  <div key={image} className="image">
                    <img src={image} width="250" height="200" alt="upload" />
                    {url[index] && (
                       <button onClick={() => deleteHandler(image, index)}>
                       <MdDeleteForever style={{ fontSize: '20px' }} />
                         </button>
                          )}
                    {/* <button  onClick={() => deleteHandler(image, index)}>
                     <MdDeleteForever style={{ fontSize: '20px'}} />
                    </button> */}
                    <p>{index + 1}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {url.length > 0 &&
            (url.length > 4 ? (
              <p className="error">
                You can't upload more than 4 images! <br />
                <span>
                  please delete <b> {selectedImages.length - 4} </b> of them{" "}
                </span>
              </p>
            ) : (
              <div>
                <br />
                
                <button className="updatedbutton" type="submit">
                  Done Submit
                </button>
                
              </div>
            )
            )}
           
        </form>
      </div>
    </Fragment>
  );
};

export default GeneratePost;

const backDrop = {
  // height:40,
  // width:60,
  position: 'relative',
  // top: "206%",
  // right: 0,
  // bottom: 0,
  // left: "57%",
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  
};