import { useState } from "react";
import "./ViewPostDetailes.css";

let ImageSlider = ({ slides }) => {
  const [wordData, setWordData] = useState(slides[0])
  const [val, setVal] = useState(0)

  const handleNext = () => {
    let index = val < slides.length - 1 ? val + 1 : val;
    setVal(index)
    const wordSlider = slides[index];
    setWordData(wordSlider)
  }
  const handlePrevious = () => {
    let index = val <= slides.length - 1 && val > 0 ? val - 1 : val;
    setVal(index)
    const wordSlider = slides[index];
    setWordData(wordSlider)
  }
  const handleClick = (index) => {
    console.log(index)
    setVal(index)
    const wordSlider = slides[index];
    setWordData(wordSlider)
  }
  return (
    <div className="maina">
      <div className="showingImageDiv">
        <div className="leftarrow" onClick={handlePrevious}>
          ‹
        </div>
        <img src={wordData} />
        <div className="rightarrow" onClick={handleNext} >
          ›
        </div>
      </div>
        
      <div className='flex_row'>
        {slides.map((data, i) =>
          <div className="thumbnail" key={i} >
            <img className={wordData == i ? "clicked" : ""} src={data} onClick={() => handleClick(i)} height="20px" width="30px" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;