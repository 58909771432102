import * as React from "react";

const LogoMain = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 473.19 113.07"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{isolation:isolate;}.cls-2{fill:url(#Degradado_sin_nombre_213);}.cls-3{fill:url(#Degradado_sin_nombre_213-2);}.cls-4{fill:url(#Degradado_sin_nombre_213-3);}.cls-5{mix-blend-mode:multiply;}.cls-6{fill:url(#Degradado_sin_nombre_213-4);}.cls-7{fill:url(#Degradado_sin_nombre_213-5);}.cls-8{fill:url(#Degradado_sin_nombre_213-6);}"
        }
      </style>
      <linearGradient
        id="Degradado_sin_nombre_213"
        x1={63.66}
        y1={236.91}
        x2={126.4}
        y2={236.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.04} stopColor="#2a9476" />
        <stop offset={0.88} stopColor="#195658" />
      </linearGradient>
      <linearGradient
        id="Degradado_sin_nombre_213-2"
        x1={101.75}
        y1={287.26}
        x2={148}
        y2={287.26}
        xlinkHref="#Degradado_sin_nombre_213"
      />
      <linearGradient
        id="Degradado_sin_nombre_213-3"
        x1={44}
        y1={285.5}
        x2={90.84}
        y2={285.5}
        xlinkHref="#Degradado_sin_nombre_213"
      />
      <linearGradient
        id="Degradado_sin_nombre_213-4"
        x1={123.13}
        y1={118.15}
        x2={141.21}
        y2={134.3}
        gradientTransform="translate(29.51 449.45) rotate(-115.13)"
        xlinkHref="#Degradado_sin_nombre_213"
      />
      <linearGradient
        id="Degradado_sin_nombre_213-5"
        x1={80.06}
        y1={289.15}
        x2={98.73}
        y2={271.78}
        gradientTransform="matrix(1, -0.04, 0.04, 1, -7.09, 3.81)"
        xlinkHref="#Degradado_sin_nombre_213"
      />
      <linearGradient
        id="Degradado_sin_nombre_213-6"
        x1={84.62}
        y1={254.78}
        x2={104.33}
        y2={272.37}
        xlinkHref="#Degradado_sin_nombre_213"
      />
    </defs>
    <g className="cls-1">
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-2"
          d="M126.4,244.28s-10.53-4.44-22.72,5.21C93.8,257.32,80.88,256.27,75,248a34.89,34.89,0,0,0-7.4,7.33,1.56,1.56,0,0,1-2.41.08l-1.12-1.25A1.64,1.64,0,0,1,64,252c3.29-3.95,8.64-9.34,13.58-11.79,14.59-7.26,26.55-5.07,26.69-5-.11-.07-10.29-6.5-32.32,4.65a22.64,22.64,0,0,1-.14-3.63c.34-9,11.34-20.84,26.76-17.77C119,222.5,126.4,244.28,126.4,244.28Z"
          transform="translate(-37 -217.93)"
        />
        <path
          className="cls-3"
          d="M139.08,308.35c-16.14,13.34-37.33,5.18-37.33,5.18s10.1-5.38,10.61-21.09c.42-12.74,9.47-22.16,19.52-21.44a35.75,35.75,0,0,0-.8-10.48,1.6,1.6,0,0,1,1.48-1.93l1.66-.07a1.59,1.59,0,0,1,1.63,1.32c.87,5.1,1.52,12.73.21,18.17-3.86,16-13.16,24-13.27,24.08.12,0,11.49-3.89,17.17-28.24a22.53,22.53,0,0,1,2.83,2.22C149.39,282.11,151.27,298.27,139.08,308.35Z"
          transform="translate(-37 -217.93)"
        />
        <path
          className="cls-4"
          d="M90.68,311.15,90,312.67a1.58,1.58,0,0,1-1.92.82c-4.82-1.64-11.73-4.7-15.8-8.46-12-11.1-14.46-23.21-14.49-23.35,0,.13-2.12,12.13,16.22,28.81A22.32,22.32,0,0,1,70.69,312c-8.36,3-23.23-3-26.13-18.7-3.84-20.79,13.31-35.85,13.31-35.85S57.71,269,71,277c10.82,6.45,14.65,19,9.24,27.68a34,34,0,0,0,9.43,4.27A1.62,1.62,0,0,1,90.68,311.15Z"
          transform="translate(-37 -217.93)"
        />
        <g className="cls-5">
          <image
            width={117}
            height={76}
            transform="translate(0 37.07)"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAABMCAYAAABTcfjZAAAACXBIWXMAAAsSAAALEgHS3X78AAADHElEQVR4Xu3bTU/jPBSG4fPl0O5Blfr/fxsSovvQ2M/xLNpAmnkleGcz46NzS1ZayCZcje2ognvvlMVKvjshG69EDViiBixRA5aoAUvUgCVqwBI1YIkasEQNWKIGLFEDlqgBS9SAJWrAEjVgiRqwRA1YogYsUQOWqAFL1IAlasASNWCJGrBEDViiBixRA5aoAUvUgCVqwOy7E0aMmfm7c/b1QP+oyyNfyw7vJ6+37S+8747DQg+JesdcsfgHgzZHokfAn4yhgIeafjd3JtNtP7Ae5eXlRQEIAHV36b1L713o9sHd4hIRdWbuzOzrUFWoqquqv729ORGBbqB+P38Y2KHu1DuqrON8PuuyLFprNQB2OBzM3c3d1d1tmiZ1d11x76MTURcRMDNqrWDmpqpNVds8z83Mmpnh/f0ddMP1++gjwA6DuplyhYj0fD7rx8eHtdbs6elpAlAATGZW3L2oaum9232oqgoRUWuNmBn30QBUEakiUmut1cyW6/VaVbVO01RHhB1q+qXNOgmA3V3ud6UCsFKKASiqOrl76b0XVV3vXiEiEpF12m0AVFUFABMRTdPUa61+OBz8er0CgNAN8n/vpv9mo6F+bl5UdV0Xt2sjmBmttWZm7O4EoKuq09czeW+tOTNDRBozV2ZuzIxlWaCqLiLOzF1EHjZLozTa9Ev0tabq6XTSZVmstWbH49EAGADrvVspxe7rqfbepZTCRMS11s7MTkQuIliWBSLSRKSpajOzOs9zK6W0y+WyTr3r9PvPT71EA6ESPayrD+vr6XSSWqu6uwDQdee7juPx+LD7nef58+6mr03T5w54mia8vr6ua+gwa+naUKhEvz3W/Bfy/mf0/PzMRETuziLSL5cL0e45lDaPL5vh6+9GASUaEHXbDnh73L/ev99e9P4PsEUe5tl029Co+zbIf9yIiPtCoWa38qu3gCVqwBI1YIkasEQNWKIGLFEDlqgBS9SAJWrAEjVgiRqwRA1YogYsUQOWqAFL1IAlasASNWCJGrBEDViiBixRA5aoAUvUgCVqwBI1YIkasEQNWKIG7BcE+bZ+0VGL1gAAAABJRU5ErkJggg=="
          />
        </g>
        <path
          className="cls-6"
          d="M89.34,259.2a15.51,15.51,0,0,0-5.41,3.72,15.09,15.09,0,0,0-3,16.34,12.33,12.33,0,0,0,4.27,5.47,7.47,7.47,0,0,0,6.93.85,5.26,5.26,0,0,0,3.17-5.81c-.83-3.28-4.92-4.63-6.67-7.53a7.37,7.37,0,0,1-1-6.71s1.87-7,10.89-7.2A15.89,15.89,0,0,0,89.34,259.2Z"
          transform="translate(-37 -217.93)"
        />
        <path
          className="cls-7"
          d="M84.23,283.71a15.76,15.76,0,0,0,22.1.84,12.23,12.23,0,0,0,3.77-5.82,7.14,7.14,0,0,0-1.53-6.7,5.46,5.46,0,0,0-6.64-.95c-2.86,1.88-2.75,6.12-4.93,8.73A7.67,7.67,0,0,1,90.9,283s-7.39.61-10.67-7.66A15.56,15.56,0,0,0,84.23,283.71Z"
          transform="translate(-37 -217.93)"
        />
        <path
          className="cls-8"
          d="M111.27,274a15.08,15.08,0,0,0-1.13-6.39A15.51,15.51,0,0,0,96.35,258a12.55,12.55,0,0,0-6.85,1.48,7.23,7.23,0,0,0-3.73,5.81,5.31,5.31,0,0,0,4,5.29c3.37.65,6.35-2.42,9.77-2.75a7.69,7.69,0,0,1,6.57,2s5.64,4.63,2,12.76A15.34,15.34,0,0,0,111.27,274Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M161.56,247.56a96.11,96.11,0,0,1,14.8-1c9.21,0,15.22,1.69,19.87,5.2,5,3.77,8.21,9.69,8.21,18.27,0,9.3-3.37,15.73-8,19.66-5.1,4.25-12.92,6.28-22.38,6.28a93.23,93.23,0,0,1-12.47-.71Zm11,39.61a26,26,0,0,0,3.83.18c9.94.09,16.47-5.4,16.47-17,0-10.09-5.88-15.39-15.32-15.39a23.58,23.58,0,0,0-5,.42Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M211.56,271.8c0-5.18-.09-8.58-.28-11.62h9.41l.4,6.53h.27a10.32,10.32,0,0,1,9.51-7.32,9.86,9.86,0,0,1,2.34.2V269.9a13.44,13.44,0,0,0-3-.31c-4,0-6.77,2.14-7.51,5.56a13,13,0,0,0-.2,2.44v17.84h-11Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M273.11,277.45c0,12.88-9.12,18.77-18.57,18.77-10.25,0-18.2-6.78-18.2-18.16s7.5-18.67,18.83-18.67C266,259.39,273.11,266.81,273.11,277.45Zm-25.48.36c0,6,2.54,10.58,7.21,10.58,4.28,0,7-4.28,7-10.64,0-5.19-2-10.53-7-10.53C249.63,267.22,247.63,272.59,247.63,277.81Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M279.89,272c0-4.64-.14-8.54-.29-11.85h9.52l.51,4.94h.15c2.61-3.73,6.62-5.73,11.73-5.73,7.71,0,14.65,6.71,14.65,17.95,0,12.85-8.19,18.88-16,18.88-4.21,0-7.58-1.69-9.14-4h-.14v17.48h-11Zm11,8.31a10.35,10.35,0,0,0,.22,2.29,6.64,6.64,0,0,0,6.4,5.19c4.74,0,7.56-4,7.56-10,0-5.6-2.5-9.88-7.38-9.88a6.86,6.86,0,0,0-6.55,5.54,7.47,7.47,0,0,0-.25,2Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M338.25,284.1a27.08,27.08,0,0,0,12.16,3c5,0,7.71-2.1,7.71-5.29,0-3-2.31-4.74-8.12-6.78-8.1-2.87-13.39-7.29-13.39-14.38,0-8.27,6.95-14.56,18.36-14.56a28.82,28.82,0,0,1,12.39,2.44l-2.42,8.8a23.31,23.31,0,0,0-10.14-2.3c-4.77,0-7.05,2.21-7.05,4.69,0,3.08,2.68,4.44,9,6.82,8.59,3.21,12.57,7.67,12.57,14.53,0,8.11-6.2,15.06-19.56,15.06-5.5,0-11-1.49-13.71-3Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M403.32,294.54a25.84,25.84,0,0,1-9.81,1.64c-11.39,0-18.64-7-18.64-18,0-10.3,7.05-18.72,20.15-18.72a25.12,25.12,0,0,1,8.36,1.39L401.67,269a14.73,14.73,0,0,0-6.13-1.12c-5.79,0-9.51,4.11-9.47,9.87,0,6.47,4.35,9.91,9.65,9.91a16.2,16.2,0,0,0,6.31-1.15Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M409.34,271.8c0-5.18-.09-8.58-.28-11.62h9.41l.4,6.53h.27a10.32,10.32,0,0,1,9.51-7.32,9.86,9.86,0,0,1,2.34.2V269.9a13.44,13.44,0,0,0-3-.31c-4,0-6.77,2.14-7.51,5.56a13,13,0,0,0-.2,2.44v17.84h-11Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M456,295.43l-.65-3.51h-.23c-2.3,2.81-5.89,4.3-10.06,4.3-7.18,0-11.4-5.15-11.4-10.78,0-9.19,8.22-13.54,20.69-13.52v-.45c0-1.87-1-4.55-6.46-4.54a20.26,20.26,0,0,0-9.71,2.63l-2-7a28.46,28.46,0,0,1,13.7-3.16c11.73,0,15.44,6.93,15.44,15.21v12.29a51.36,51.36,0,0,0,.56,8.54Zm-1.33-16.67c-5.76,0-10.22,1.36-10.22,5.55,0,2.84,1.86,4.22,4.35,4.22a6,6,0,0,0,5.67-4,6.71,6.71,0,0,0,.2-1.87Z"
          transform="translate(-37 -217.93)"
        />
        <path
          d="M473.93,272c0-4.64-.14-8.54-.29-11.85h9.52l.51,4.94h.14c2.62-3.73,6.63-5.73,11.74-5.73,7.71,0,14.64,6.71,14.64,17.95,0,12.85-8.18,18.88-16,18.88-4.21,0-7.58-1.69-9.14-4h-.14v17.48h-11Zm11,8.31a10.35,10.35,0,0,0,.22,2.29,6.64,6.64,0,0,0,6.4,5.19c4.73,0,7.56-4,7.56-10,0-5.6-2.51-9.88-7.38-9.88a6.86,6.86,0,0,0-6.55,5.54,7.47,7.47,0,0,0-.25,2Z"
          transform="translate(-37 -217.93)"
        />
      </g>
    </g>
  </svg>
);
export default LogoMain;