import React from 'react';
import "./FooterStyles.css"
import { FaFacebookF, FaXTwitter, FaInstagram, FaYoutube } from "react-icons/fa6";
import { Link } from "react-router-dom"
import SVGComponent from '../../assets/applyplaystorlog';
import PlaystoreAndroid from '../../assets/playstore2';
function Footer() {
  return (
    <div className="fotterstart">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3 item">
              <h3>Products</h3>
              <ul>
                <Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{
                    pathname: "/viewmore/Plastic",
                  }}
                >
                  <span>Plastic</span>
                </Link>
                <li><Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{
                    pathname: "/viewmore/Metal",
                  }}
                >

                  <span>Metal</span>
                </Link></li>
                <li><Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{
                    pathname: "/viewmore/Copper",

                  }}
                >
                  <span>Copper</span>
                </Link></li>
                <li><Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{ pathname: "/viewmore/Steel" }}
                >
                  <span>Steel</span>
                </Link>
                </li>
                <li> <Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{ pathname: "/viewmore/Aluminium" }}
                >
                  <span>Aluminium</span>
                </Link>
                </li>
                <li> <Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{ pathname: "/viewmore/Others" }}
                >
                  <span>Others</span>
                </Link></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 item">
              <div className='abouttop'><h3>About</h3></div>
              
              <ul>
              <li> <Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{ pathname: "/about" }}
                >
                  <span>DropScrap</span>
                </Link></li>
              </ul>
            </div>
            <div className="col-md-6 item text">
                            
                            <div className='abouttop'><h3>Download Mobile Apps</h3></div>
                            <a href="" title="DropScrap Android App" ><PlaystoreAndroid style={{height:"41px"}} /></a>
                            <a href="" title="DropScrap IOS Application" ><SVGComponent style={{height:"41px"}} /></a>

                        </div>

            <div className="col item social">
              <a href="https://www.facebook.com/profile.php?id=61558798318333&mibextid=ZbWKwL"><i><FaFacebookF /></i></a>
              <a href="https://twitter.com/dropscraps"><i><FaXTwitter /></i></a>
              <a href="https://www.instagram.com/dropscrap?igsh=ejRlbWI3enN3NXc5"><i><FaInstagram /></i></a>
              <a href="https://www.youtube.com/@dropscrap"><i><FaYoutube /></i></a>
            </div>
          </div>
          <div className="privacypolices">
            <Link style={{ color: 'white', opacity: "0.3", fontSize: "13px" }} to={{
              pathname: "/term",
            }}>
              <span>Terms of Service</span>
            </Link>
            <span style={{ color: 'white', opacity: "0.3", fontSize: "13px" }}> | </span>
            <Link style={{ color: 'white', opacity: "0.3", fontSize: "13px" }} to={{
              pathname: "/privacy",
            }}>
              <span>Privacy Policy</span>
            </Link>
          </div>
          <p className="copyright">Copyright © 2024 DropScrap - All Rights Reserved.</p>

        </div>
      </footer>
    </div>
  );
}

export default Footer;
