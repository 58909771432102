import React from 'react'
import Header from "../Header/Header"
import AllPostdetail from '../ViewMorePostsScrap/Allfilterposts'

function ViewMorePosts() {
    return (
        <div>
         <Header/>
         <AllPostdetail/> 
         
        </div>
    )
}

export default ViewMorePosts
