import React, { useState, useEffect, useContext } from "react";
import { Firebase } from "../../firebase/config";
import "./userpostes.css";
import { useAuth } from '../../Auth/AuthContext copy';
import PostCards3 from "../UserPostCard/Postcard3";
import { useParams} from "react-router-dom";
function UserPost() {
  const [posts, setPosts] = useState([]);
  const { currentUser } = useAuth();
  const {id}=useParams();
  useEffect(() => {
    console.log("userpost")
    // console.log(currentUser.uid)
      try{
        Firebase.firestore() 
        .collection("products").where("userId","==",id)
        .orderBy("time", "desc")
        .get()
        .then((snapshot) => {
          let filterpost = snapshot.docs.map((product) => {
            const idd=product.id;
            return {
                id: idd,
              ...product.data(),
              
            };
          });
          setPosts(filterpost);
        });
      }catch{

      }
   
  }, []);

  /**
  * used to apply pagination on posts
  * @param {String} key
  * @return next batch of posts (+5 posts)
  * will be fired when user click on 'More Posts' button.
  */
 

  let allPosts = (
    posts.map((product, index) => {
      return (
        <div className="filteration" key={index}>
          {" "}
          <PostCards3 product={product} index={index} />{" "}
        </div>
      );
    })
  );

  return (
    <>
     
      <div className="tagent">
        <div className="bocing">
        <div className="containring">
              {allPosts}
            </div>
        </div>
       
      </div>

    </>
  );
}

export default UserPost;