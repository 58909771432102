import React from "react";
import "./App.css";
import ContextPost from "./Auth/PostContext";
import Routers from "./Screens/RoutesOfPages/MainRoutes";
import AuthContext from "./Auth/AuthContext copy";
import Modal from "./Screens/Login/Modal"
import MainNotification from './Screens/Header/MainNotification';
import Verification from './Screens/Header/Verification';
import Loading from './Screens/Login/Loading';
import NoInternetConnection from './checkinternet';

function App() {
  return (
    <div className="mainview">
     < NoInternetConnection>
          <ContextPost>
          <AuthContext>
            <Loading />
            <Modal />
            <Verification />
            <MainNotification />
            <Routers />
            </AuthContext>
          </ContextPost>
      </NoInternetConnection>
    </div>
  );
}

export default App;
