import React, { Fragment, useContext } from "react";
import GeneratePost from "../CreatePost/Generate";

import { Close } from '@mui/icons-material';
import { Alert, Box, Button, Collapse, IconButton } from '@mui/material';
import { useAuth } from '../../Auth/AuthContext copy';
import Loogin from "../Login/Login"
import { useState } from 'react';
const CreateScrap = () => {
  const [open, setOpen] = useState(true);
  const { currentUser, setModal, logout, setAlert } = useAuth();
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };
   
  return (
    <Fragment>
      <div style={{backgroundColor: "#ebebeb"}}>
      {
         currentUser === null || currentUser === undefined ?(
          <Box>
        <Collapse in={open}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="Close"
                size="small"
                onClick={() => setOpen(false)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 3 }}
          >
            Your are not Login!
            <Button
              size="small"
              onClick={openLogin}
              sx={{ lineHeight: 'initial' }}
            >
              Login
            </Button>
          </Alert>
        </Collapse>
      </Box>
          
         ):
         (
          currentUser.emailVerified?(
            <GeneratePost />
          ):(
            <Box>
        <Collapse in={open}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="Close"
                size="small"
                onClick={() => setOpen(false)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 3 }}
          >
            Verify Your Email !
           
          </Alert>
        </Collapse>
      </Box>
          )
         )
        
        }
      </div>
     
    </Fragment>
  );
};

export default CreateScrap;
