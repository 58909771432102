import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/storage'
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDyo1nm8R9xBi-F-HJvNBVbgLDs9s8iJ7I",
  authDomain: "scrapdealing-b7e23.firebaseapp.com",
  projectId: "scrapdealing-b7e23",
  storageBucket: "scrapdealing-b7e23.appspot.com",
  messagingSenderId: "3553367304",
  appId: "1:3553367304:web:5e09d5bce853892641c8bd"
};
  
  export const Firebase= firebase.initializeApp(firebaseConfig)//named export
  export const auth = getAuth();   
  export const dbb = firebase.firestore();
  export const storage = getStorage();
  export const db = getFirestore();
 