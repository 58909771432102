import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import { Firebase } from "../../firebase/config";
import { useNavigate } from "react-router";
import ImageSlider from "./slider";
import PostCards2 from "../PostCardScrap/postcard2";
import { SlLocationPin } from "react-icons/sl";
import Avatar from '@mui/material/Avatar';
import 'react-slideshow-image/dist/styles.css'
import "./ViewPostDetailes.css";
import Loogin from "../Login/Login"
import { useAuth } from '../../Auth/AuthContext copy';
import {Button} from '@mui/material';

function ViewPostDetailing() {

  const navigate = useNavigate();
  const [scrapPostDetails, setscrapPostDetails] = useState({});
  // const [buttonText, setButtonText] = useState('Show Phone Number');
  const [userDetails, setUserDetails] = useState();//we want show the details of who is posted the add and we dont know,so we want retreive user data from firebase who is posted this add
  const {productid}=useParams();
  const { currentUser, setModal } = useAuth();
  let [related, setrealted] = useState([]);
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };

  // function handleClick() {
  //   console.log(currentUser)
  //   if(currentUser === null || currentUser === undefined){
  //     setModal({ isOpen: true, title: 'Login', content: <Loogin /> }); 
  //   }
  //   else{   
  //   if(currentUser.emailVerified){
  //     console.log("see number")
  //     if(userDetails.phone!=null){
  //       setButtonText(userDetails.phone);
  //     }
  //     else{
  //       setButtonText(scrapPostDetails.phone);
  //     }
  //   }
  //   else{
  //     <Box>
  //     <Collapse in={open}>
  //       <Alert
  //         severity="warning"
  //         action={
  //           <IconButton
  //             aria-label="Close"
  //             size="small"
  //             onClick={() => setOpen(false)}
  //           >
  //             <Close fontSize="inherit" />
  //           </IconButton>
  //         }
  //         sx={{ mb: 3 }}
  //       >
  //         Verify Your Email !
         
  //       </Alert>
  //     </Collapse>
  //   </Box>
  //   }
  //   }
  // }
  var ago= timeSince(new Date(scrapPostDetails.createdAt));;
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }
 
  //if user click the refresh of the page then PostContext data will be erased so it will throws an error so that time we want redirect this page to home page
  let slides = scrapPostDetails.url
  useEffect(() => {

    console.log("VIEWS")
    Firebase.firestore() 
    .collection("products")
    .doc(productid)
    .onSnapshot(function (doc) {
      setscrapPostDetails(doc.data());
    Firebase.firestore()
      .collection("users").doc(doc.data().userId)
      .get().then(
        (res) => {
          setUserDetails(res.data());
             });

        try {
          Firebase.firestore()//retreving all posts from firebase in asecnding order of date
         .collection("products").where("category","==",doc.data().category)
         .where("country","==",doc.data().country).where("createdAt","!=",doc.data().createdAt)
         .orderBy("createdAt", "desc").limit(4)
         .get()
         .then((snapshot) => {
           let filterpost = snapshot.docs.map((product) => {
             const idd=product.id;
             return {
                 id: idd,
               ...product.data(),
               
             };
           });
           setrealted(filterpost);
         });
        
         }
          catch (e) {
           console.log(e);
         }

    }
    );
  }, []);

 
  

  let relateposts = (
  
    related.map((product, index) => {
      return (
        <PostCards2 product={product} index={index} key={index} />
      );
    })
  );

  return (
    <div className="postdetailing">
      <div className="showingImage">
        {
          slides && (

            <div>
              <ImageSlider slides={slides} />
            </div>

          )
        }
      </div>
      <div className="right">
      <div className="rightPhase">
        <div className="productDetails">
             <div className="postname">{scrapPostDetails.name}</div>
             <p className="descriptions">{scrapPostDetails.description}</p>
             <div className="productprice">
             <span className="leftside">Price</span>
             <span className="rightside">{scrapPostDetails.countryunit} {scrapPostDetails.price} </span>
             </div>
             <div className="weightdetail">
             <span className="leftside">Weight</span>
             <span className="rightside">{scrapPostDetails.weight} {scrapPostDetails.weightunit}</span>
             </div>
             <div className="catagorydetail">
             <span className="leftside">Category</span> 
             <span className="rightside" > {scrapPostDetails.category}</span>
             </div>
             <div className="addressdetail">
             <span className="leftside">Address</span>
             <span className="rightside">{scrapPostDetails.location}</span>
             </div>
             <div className="location">
             <SlLocationPin style={{ fontSize: '20px'}} />
             <div className="countryname" >{scrapPostDetails.country}</div>  
             <div className="createdat" style={{ fontSize: '12px'}}>{ago}</div>
             </div>
        </div>
      
      </div>
      <div className="sellerdetailbox">
      {userDetails &&
          <div className="contactDetails">
            <div className="sellerbox">
            <div className="sellericon">Seller Profile</div>
            </div>
           <div className="avatarnameofseller">
            <div className="avatar"> 
            <Avatar
                sx={{ width: 70, height: 70 }}
                src={userDetails?.display_picture}
              >
                 {userDetails?.name?.charAt(0)?.toUpperCase()
                  }
              </Avatar>
               </div>
           <div className="nameofseller">
                {userDetails.name}
                <div className="location">
               <SlLocationPin style={{  fontSize: '14px',color:'rgb(251, 197, 38)',marginTop:'3px' }} />
               <div className="countryname" style={{fontWeight:'normal', fontSize: '15px'}}>{scrapPostDetails.country}</div>  
                   </div>
           </div>
           </div>
               <div className="shownumberbutton">
              
              {!currentUser ? (
              <Button style={{
                fontWeight: 'bold',
                color:"white",
                cursor: "pointer",
              }}  onClick={openLogin}>
                Show Phone Number
              </Button>
            ) : (
              currentUser.emailVerified ? (userDetails.phone || scrapPostDetails.phone ):(
                "Verify Your Email"
              )
            )}
          
                </div>
          </div>
        }
       </div>
       
      </div>
      <div className="relatename">Similar Ads</div>
      <div className="recommendationsrelated" >
      <div className="relatedfresh-recomendation-cards" >{relateposts}</div>
      </div>
      

    </div>
  );
}
export default ViewPostDetailing;
