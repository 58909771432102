import React from 'react';
import Login from '../Login/Login';

function LoginPage() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;
