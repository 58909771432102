import React from 'react'

import Header from '../Header/Header'

import Footer from '../Footer/Footer';
import TermANDCondition from '../Policyes/TermAndCondition';

function TermConditionMake(props) {
    
    return (
        <div style={{backgroundColor: "#ebebeb"}}>
            <Header />
            <TermANDCondition />
            <Footer />
        </div>
    )
}

export default TermConditionMake