import React from 'react'
import Header from "../Header/Header"
import SearchPostdetail from '../Searchpost/searchingpost'

function SearchPostComplete() {
    return (
        <div>
         <Header/>
         <SearchPostdetail/>        
        </div>
    )
}

export default SearchPostComplete