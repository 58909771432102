import React, { useState } from "react";
import "./picturebaner.css"

function  Picturebaner() {

  return (
    <div className="picture">
      <div className="picturechild">
        <div className="banerpic">
          <img src="../../../Images/bannerr.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Picturebaner;
