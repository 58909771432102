import { deleteObject, ref } from 'firebase/storage';

import { Firebase } from "../../firebase/config";
const deleteFile = (filePath) => {
  console.log(filePath.length)
 const deleting= filePath.map((num)=> {
    const fileRef = Firebase.storage().refFromURL(num);
    
      fileRef.delete().then(function () {
        console.log("File Deleted")
      }).catch(function (error) {
      
      });
    
  });
return deleting
  
};

export default deleteFile;
