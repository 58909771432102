import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="artwork"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 98 34"
    style={{
      enableBackground: "new 0 0 98 34",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:#FFFFFF;fill-opacity:0;}\n\t.st1{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.2;stroke-miterlimit:10;}\n\t.st2{fill:#FFFFFF;}\n\t.st3{fill:url(#SVGID_1_);}\n\t.st4{fill:url(#SVGID_2_);}\n\t.st5{fill:url(#SVGID_3_);}\n\t.st6{fill:url(#SVGID_4_);}\n\t.st7{opacity:0.2;enable-background:new    ;}\n\t.st8{opacity:0.12;enable-background:new    ;}\n\t.st9{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}\n"
      }
    </style>
    <g>
      <rect x={-6} y={-4.3} className="st0" width={110} height={42.6} />
      <g>
        <path d="M93.4,31.2H4.6c-2,0-3.5-1.6-3.5-3.5V6.4c0-2,1.6-3.5,3.5-3.5h88.7c2,0,3.5,1.6,3.5,3.5v21.3 C96.9,29.6,95.3,31.2,93.4,31.2z" />
      </g>
      <g>
        <g>
          <path d="M93.4,3.4c1.6,0,3,1.3,3,3v21.3c0,1.6-1.3,3-3,3H4.6c-1.6,0-3-1.3-3-3V6.4c0-1.6,1.3-3,3-3H93.4 M93.4,2.8H4.6 c-2,0-3.5,1.6-3.5,3.5v21.3c0,2,1.6,3.5,3.5,3.5h88.7c2,0,3.5-1.6,3.5-3.5V6.4C96.9,4.4,95.3,2.8,93.4,2.8L93.4,2.8z" />
        </g>
      </g>
      <g>
        <path
          className="st1"
          d="M34.7,10.1c0,0.6-0.2,1.1-0.5,1.4c-0.4,0.4-0.9,0.6-1.6,0.6c-0.6,0-1.1-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.6 c0-0.6,0.2-1.2,0.6-1.6c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.6,0.1,0.9,0.2C33.8,8,34,8.1,34.2,8.3l-0.4,0.4c-0.3-0.3-0.7-0.5-1.2-0.5 c-0.4,0-0.8,0.2-1.2,0.5C31.2,9,31,9.4,31,9.9s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5 c0.2-0.2,0.3-0.5,0.4-0.9h-1.5V9.8h2.1C34.7,9.9,34.7,10,34.7,10.1z"
        />
        <path
          className="st1"
          d="M38,8.3h-1.9v1.4h1.7v0.5h-1.7v1.4H38V12h-2.5V7.8H38V8.3z"
        />
        <path className="st1" d="M40.3,12h-0.5V8.3h-1.2V7.8h2.9v0.5h-1.2V12z" />
        <path className="st1" d="M43.6,12V7.8h0.5V12H43.6z" />
        <path className="st1" d="M46.6,12h-0.5V8.3h-1.2V7.8h2.9v0.5h-1.2V12z" />
        <path
          className="st1"
          d="M53.3,11.5c-0.4,0.4-0.9,0.6-1.6,0.6c-0.6,0-1.1-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.6s0.2-1.1,0.6-1.6 c0.4-0.4,0.9-0.6,1.6-0.6c0.6,0,1.1,0.2,1.6,0.6c0.4,0.4,0.6,1,0.6,1.6C54,10.5,53.8,11.1,53.3,11.5z M50.6,11.1 c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.8-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2S53.2,9,52.9,8.7c-0.3-0.3-0.7-0.5-1.2-0.5 c-0.5,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2S50.3,10.8,50.6,11.1z"
        />
        <path
          className="st1"
          d="M54.7,12V7.8h0.7l2.1,3.3h0l0-0.8V7.8H58V12h-0.6l-2.2-3.5h0l0,0.8V12H54.7z"
        />
      </g>
      <path
        className="st2"
        d="M49.5,18.2c-1.7,0-3,1.3-3,3c0,1.7,1.4,3,3,3c1.7,0,3-1.3,3-3C52.5,19.5,51.1,18.2,49.5,18.2z M49.5,23.1 c-0.9,0-1.7-0.8-1.7-1.8c0-1.1,0.8-1.8,1.7-1.8c0.9,0,1.7,0.7,1.7,1.8C51.2,22.3,50.4,23.1,49.5,23.1z M42.8,18.2c-1.7,0-3,1.3-3,3 c0,1.7,1.4,3,3,3c1.7,0,3-1.3,3-3C45.9,19.5,44.5,18.2,42.8,18.2z M42.8,23.1c-0.9,0-1.7-0.8-1.7-1.8c0-1.1,0.8-1.8,1.7-1.8 c0.9,0,1.7,0.7,1.7,1.8C44.5,22.3,43.8,23.1,42.8,23.1z M35,19.2v1.3H38c-0.1,0.7-0.3,1.2-0.7,1.6C36.9,22.5,36.2,23,35,23 c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1,0,1.8,0.4,2.3,0.9l0.9-0.9c-0.8-0.7-1.8-1.3-3.2-1.3c-2.6,0-4.8,2.1-4.8,4.7 c0,2.6,2.2,4.7,4.8,4.7c1.4,0,2.4-0.5,3.3-1.3c0.8-0.8,1.1-2,1.1-3c0-0.3,0-0.6-0.1-0.8H35z M67.1,20.2c-0.3-0.7-1-1.9-2.6-1.9 c-1.6,0-2.8,1.2-2.8,3c0,1.7,1.3,3,3,3c1.4,0,2.2-0.8,2.5-1.3l-1-0.7c-0.3,0.5-0.8,0.8-1.5,0.8c-0.7,0-1.2-0.3-1.5-0.9l4-1.7 L67.1,20.2z M63,21.2c0-1.2,0.9-1.8,1.6-1.8c0.5,0,1,0.3,1.1,0.6L63,21.2z M59.7,24.1h1.3v-8.9h-1.3V24.1z M57.6,18.9L57.6,18.9 c-0.3-0.4-0.9-0.7-1.6-0.7c-1.5,0-2.9,1.3-2.9,3c0,1.7,1.4,3,2.9,3c0.7,0,1.3-0.3,1.6-0.7h0V24c0,1.2-0.6,1.8-1.6,1.8 c-0.8,0-1.3-0.6-1.5-1.1l-1.2,0.5C53.6,26,54.5,27,56,27c1.6,0,2.9-0.9,2.9-3.1v-5.4h-1.3V18.9z M56,23.1c-0.9,0-1.7-0.8-1.7-1.8 c0-1.1,0.8-1.8,1.7-1.8c0.9,0,1.6,0.8,1.6,1.8C57.7,22.3,56.9,23.1,56,23.1z M73.3,15.2h-3.2v8.9h1.3v-3.4h1.8 c1.5,0,2.9-1.1,2.9-2.8C76.3,16.3,74.8,15.2,73.3,15.2z M73.4,19.5h-1.9v-3h1.9c1,0,1.6,0.8,1.6,1.5C74.9,18.7,74.4,19.5,73.4,19.5 z M81.6,18.2c-1,0-2,0.4-2.4,1.4l1.2,0.5c0.3-0.5,0.7-0.7,1.2-0.7c0.7,0,1.4,0.4,1.4,1.1v0.1c-0.2-0.1-0.8-0.3-1.4-0.3 c-1.3,0-2.6,0.7-2.6,2c0,1.2,1,2,2.2,2c0.9,0,1.4-0.4,1.7-0.9h0v0.7h1.3v-3.4C84.3,19.1,83.1,18.2,81.6,18.2z M81.4,23.1 c-0.4,0-1-0.2-1-0.8c0-0.7,0.8-0.9,1.4-0.9c0.6,0,0.9,0.1,1.2,0.3C82.9,22.5,82.2,23.1,81.4,23.1z M88.9,18.4l-1.5,3.8h0l-1.6-3.8 h-1.4l2.4,5.4l-1.3,3h1.4l3.6-8.4H88.9z M77,24.1h1.3v-8.9H77V24.1z"
      />
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={16.5676}
          y1={167.0125}
          x2={4.6575}
          y2={155.1024}
          gradientTransform="matrix(1 0 0 -1 0 176)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#00A0FF",
            }}
          />
          <stop
            offset={0.00657445}
            style={{
              stopColor: "#00A1FF",
            }}
          />
          <stop
            offset={0.2601}
            style={{
              stopColor: "#00BEFF",
            }}
          />
          <stop
            offset={0.5122}
            style={{
              stopColor: "#00D2FF",
            }}
          />
          <stop
            offset={0.7604}
            style={{
              stopColor: "#00DFFF",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#00E3FF",
            }}
          />
        </linearGradient>
        <path
          className="st3"
          d="M8.5,8.2c-0.2,0.2-0.3,0.6-0.3,1v15.7c0,0.4,0.1,0.8,0.3,1l0.1,0.1l8.8-8.8V17v-0.1L8.5,8.2L8.5,8.2z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={25.1083}
          y1={158.999}
          x2={7.9363}
          y2={158.999}
          gradientTransform="matrix(1 0 0 -1 0 176)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FFE000",
            }}
          />
          <stop
            offset={0.4087}
            style={{
              stopColor: "#FFBD00",
            }}
          />
          <stop
            offset={0.7754}
            style={{
              stopColor: "#FFA500",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#FF9C00",
            }}
          />
        </linearGradient>
        <path
          className="st4"
          d="M20.3,20l-2.9-2.9V17v-0.1l2.9-2.9l0.1,0l3.5,2c1,0.6,1,1.5,0,2L20.3,20L20.3,20z"
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={18.7159}
          y1={157.3705}
          x2={2.5649}
          y2={141.2195}
          gradientTransform="matrix(1 0 0 -1 0 176)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#FF3A44",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#C31162",
            }}
          />
        </linearGradient>
        <path
          className="st5"
          d="M20.3,20l-3-3l-8.8,8.8c0.3,0.3,0.9,0.4,1.5,0L20.3,20"
        />
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1={6.2755}
          y1={173.0685}
          x2={13.4876}
          y2={165.8564}
          gradientTransform="matrix(1 0 0 -1 0 176)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#32A071",
            }}
          />
          <stop
            offset={0.0685}
            style={{
              stopColor: "#2DA771",
            }}
          />
          <stop
            offset={0.4762}
            style={{
              stopColor: "#15CF74",
            }}
          />
          <stop
            offset={0.8009}
            style={{
              stopColor: "#06E775",
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#00F076",
            }}
          />
        </linearGradient>
        <path
          className="st6"
          d="M20.3,14L10,8.1c-0.6-0.3-1.1-0.3-1.5,0l8.8,8.8L20.3,14z"
        />
        <g>
          <path
            className="st7"
            d="M20.3,19.9L10,25.8c-0.6,0.3-1.1,0.3-1.4,0l0,0l-0.1,0.1l0,0l0.1,0.1l0,0c0.3,0.3,0.8,0.3,1.4,0L20.3,19.9 L20.3,19.9z"
          />
          <path
            className="st8"
            d="M8.5,25.7c-0.2-0.2-0.3-0.6-0.3-1v0.1c0,0.4,0.1,0.8,0.3,1V25.7L8.5,25.7z"
          />
        </g>
        <path
          className="st8"
          d="M23.8,17.9l-3.5,2l0.1,0.1l3.5-2c0.5-0.3,0.7-0.7,0.7-1l0,0C24.5,17.3,24.3,17.7,23.8,17.9z"
        />
        <path
          className="st9"
          d="M10,8.2l13.8,7.9c0.4,0.3,0.7,0.6,0.7,0.9l0,0c0-0.4-0.2-0.7-0.7-1L10,8.1C9,7.5,8.2,8,8.2,9.2v0.1 C8.2,8.1,9,7.7,10,8.2z"
        />
      </g>
    </g>
    <g>
      <path d="M90.7,30.7H4.5c-1.8,0-3.3-1.5-3.3-3.3v-21c0-1.8,1.5-3.2,3.3-3.2h86.3c1.8,0,3.3,1.5,3.3,3.2v21 C94,29.2,92.5,30.7,90.7,30.7L90.7,30.7z" />
      <path d="M93.3,27.5c0,1.5-1.2,2.6-2.6,2.6H4.5c-1.5,0-2.6-1.2-2.6-2.6v-21C1.8,5,3,3.8,4.5,3.8h86.3c1.5,0,2.6,1.2,2.6,2.6 L93.3,27.5L93.3,27.5z" />
      <g>
        <g>
          <path
            className="st2"
            d="M21.9,16.8c0-2.2,1.8-3.3,1.9-3.3c-1-1.5-2.6-1.7-3.2-1.7c-1.4-0.1-2.7,0.8-3.4,0.8c-0.7,0-1.8-0.8-2.9-0.8 c-1.5,0-2.8,0.9-3.6,2.2c-1.6,2.7-0.4,6.7,1.1,8.8c0.7,1.1,1.6,2.3,2.8,2.2c1.1,0,1.5-0.7,2.9-0.7c1.3,0,1.7,0.7,2.9,0.7 c1.2,0,2-1.1,2.7-2.1c0.9-1.2,1.2-2.4,1.2-2.5C24.2,20.3,21.9,19.5,21.9,16.8z"
          />
          <path
            className="st2"
            d="M19.7,10.3c0.6-0.8,1-1.8,0.9-2.8c-0.9,0-2,0.6-2.6,1.3c-0.6,0.6-1,1.7-0.9,2.7C18.1,11.6,19.1,11,19.7,10.3 z"
          />
        </g>
      </g>
      <g>
        <path
          className="st2"
          d="M38.1,24.9h-1.6l-0.9-2.7h-3l-0.8,2.7h-1.5l2.9-9.1h1.8L38.1,24.9z M35.4,21.1l-0.8-2.4 c-0.1-0.2-0.2-0.8-0.5-1.7h0c-0.1,0.4-0.2,1-0.4,1.7L33,21.1H35.4z"
        />
        <path
          className="st2"
          d="M45.7,21.5c0,1.1-0.3,2-0.9,2.7c-0.5,0.6-1.2,0.9-2,0.9c-0.9,0-1.5-0.3-1.9-0.9h0v3.5h-1.5v-7.1 c0-0.7,0-1.4-0.1-2.2h1.3l0.1,1h0c0.5-0.8,1.2-1.2,2.2-1.2c0.8,0,1.4,0.3,1.9,0.9C45.4,19.7,45.7,20.5,45.7,21.5z M44.2,21.5 c0-0.6-0.1-1.2-0.4-1.6c-0.3-0.4-0.7-0.7-1.3-0.7c-0.4,0-0.7,0.1-1,0.4c-0.3,0.2-0.5,0.6-0.6,0.9c0,0.2-0.1,0.3-0.1,0.4v1.1 c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.7,0.5,1.1,0.5c0.6,0,1-0.2,1.3-0.6C44,22.8,44.2,22.2,44.2,21.5z"
        />
        <path
          className="st2"
          d="M53.2,21.5c0,1.1-0.3,2-0.9,2.7c-0.5,0.6-1.2,0.9-2,0.9c-0.9,0-1.5-0.3-1.9-0.9h0v3.5h-1.5v-7.1 c0-0.7,0-1.4-0.1-2.2h1.3l0.1,1h0c0.5-0.8,1.2-1.2,2.2-1.2c0.8,0,1.4,0.3,1.9,0.9C53,19.7,53.2,20.5,53.2,21.5z M51.7,21.5 c0-0.6-0.1-1.2-0.4-1.6c-0.3-0.4-0.7-0.7-1.3-0.7c-0.4,0-0.7,0.1-1,0.4c-0.3,0.2-0.5,0.6-0.6,0.9c0,0.2-0.1,0.3-0.1,0.4v1.1 c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.7,0.5,1.1,0.5c0.6,0,1-0.2,1.3-0.6C51.6,22.8,51.7,22.2,51.7,21.5z"
        />
        <path
          className="st2"
          d="M61.7,22.3c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.5-1.4,0.8-2.5,0.8c-1,0-1.8-0.2-2.4-0.6l0.3-1.2 c0.6,0.4,1.3,0.6,2.1,0.6c0.6,0,1-0.1,1.3-0.4c0.3-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.7-0.5-1.3-0.7 c-1.6-0.6-2.4-1.5-2.4-2.6c0-0.8,0.3-1.4,0.8-1.8c0.6-0.5,1.3-0.7,2.2-0.7c0.8,0,1.5,0.1,2.1,0.4L61,17.2 c-0.5-0.3-1.1-0.4-1.8-0.4c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.7,0.5,1.3,0.7 c0.8,0.3,1.4,0.7,1.7,1.1C61.5,21.1,61.7,21.7,61.7,22.3z"
        />
        <path
          className="st2"
          d="M66.6,19.4H65v3.2c0,0.8,0.3,1.2,0.9,1.2c0.3,0,0.5,0,0.7-0.1l0,1.1c-0.3,0.1-0.7,0.2-1.1,0.2 c-0.6,0-1-0.2-1.4-0.5c-0.3-0.4-0.5-0.9-0.5-1.8v-3.3h-1v-1.1h1v-1.2l1.4-0.4v1.6h1.6C66.6,18.3,66.6,19.4,66.6,19.4z"
        />
        <path
          className="st2"
          d="M73.9,21.5c0,1-0.3,1.8-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.7-0.3-2.3-1c-0.6-0.6-0.9-1.5-0.9-2.4 c0-1,0.3-1.9,0.9-2.5c0.6-0.7,1.4-1,2.4-1c1,0,1.7,0.3,2.3,1C73.6,19.7,73.9,20.5,73.9,21.5z M72.3,21.6c0-0.6-0.1-1.1-0.4-1.6 c-0.3-0.5-0.7-0.8-1.3-0.8c-0.6,0-1,0.3-1.3,0.8c-0.3,0.4-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.3,0.5,0.8,0.8,1.3,0.8 c0.6,0,1-0.3,1.3-0.8C72.2,22.7,72.3,22.2,72.3,21.6z"
        />
        <path
          className="st2"
          d="M78.6,19.6c-0.1,0-0.3,0-0.5,0c-0.5,0-0.9,0.2-1.2,0.6c-0.2,0.3-0.4,0.8-0.4,1.3v3.5h-1.5l0-4.5 c0-0.8,0-1.5-0.1-2.1h1.3l0.1,1.3h0c0.2-0.4,0.4-0.8,0.7-1c0.3-0.2,0.7-0.4,1.1-0.4c0.1,0,0.3,0,0.4,0 C78.6,18.2,78.6,19.6,78.6,19.6z"
        />
        <path
          className="st2"
          d="M85.2,21.3c0,0.3,0,0.5-0.1,0.7h-4.4c0,0.7,0.2,1.2,0.6,1.5c0.4,0.3,0.8,0.5,1.4,0.5c0.7,0,1.2-0.1,1.8-0.3 l0.2,1c-0.6,0.3-1.4,0.4-2.2,0.4c-1,0-1.8-0.3-2.4-0.9c-0.6-0.6-0.9-1.4-0.9-2.4c0-1,0.3-1.8,0.8-2.5c0.6-0.7,1.3-1.1,2.3-1.1 c1,0,1.7,0.4,2.2,1.1C85,19.7,85.2,20.4,85.2,21.3z M83.8,20.9c0-0.4-0.1-0.8-0.3-1.1c-0.3-0.4-0.6-0.6-1.2-0.6 c-0.5,0-0.9,0.2-1.2,0.6c-0.2,0.3-0.4,0.7-0.4,1.1L83.8,20.9L83.8,20.9z"
        />
      </g>
      <g>
        <g>
          <path
            className="st2"
            d="M34.9,10.1c0,0.8-0.2,1.4-0.7,1.8c-0.4,0.4-1.1,0.6-1.9,0.6c-0.4,0-0.8,0-1.1-0.1V8c0.4-0.1,0.8-0.1,1.2-0.1 c0.8,0,1.4,0.2,1.8,0.5C34.7,8.8,34.9,9.4,34.9,10.1z M34.2,10.1c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.7-0.4-1.2-0.4 c-0.2,0-0.4,0-0.6,0v3.4c0.1,0,0.3,0,0.5,0c0.6,0,1-0.2,1.3-0.5C34,11.1,34.2,10.7,34.2,10.1z"
          />
          <path
            className="st2"
            d="M39,10.8c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.2 c0-0.5,0.1-0.9,0.4-1.2s0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.1,0.5C38.8,9.9,39,10.3,39,10.8z M38.2,10.8c0-0.3-0.1-0.6-0.2-0.8 c-0.2-0.3-0.4-0.4-0.6-0.4c-0.3,0-0.5,0.1-0.7,0.4c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.6,0.2,0.8c0.2,0.3,0.4,0.4,0.7,0.4 c0.3,0,0.5-0.1,0.6-0.4C38.1,11.4,38.2,11.1,38.2,10.8z"
          />
          <path
            className="st2"
            d="M44.4,9.2l-1,3.2h-0.7L42.3,11c-0.1-0.4-0.2-0.7-0.3-1h0c-0.1,0.4-0.1,0.7-0.3,1l-0.4,1.4h-0.7l-1-3.2h0.7 l0.4,1.5c0.1,0.4,0.2,0.7,0.2,1h0c0.1-0.3,0.1-0.6,0.3-1l0.5-1.5h0.6l0.4,1.5c0.1,0.4,0.2,0.7,0.3,1.1h0c0-0.3,0.1-0.7,0.2-1.1 l0.4-1.5L44.4,9.2L44.4,9.2z"
          />
          <path
            className="st2"
            d="M48.1,12.4h-0.7v-1.9c0-0.6-0.2-0.9-0.7-0.9c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.2-0.2,0.3-0.2,0.6v1.9h-0.7v-2.3 c0-0.3,0-0.6,0-0.9h0.6l0,0.5h0c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.6,0.4,1.1 V12.4z"
          />
          <path className="st2" d="M50.1,12.4h-0.7V7.7h0.7V12.4z" />
          <path
            className="st2"
            d="M54.3,10.8c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.1-0.5 c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.1,0.5C54.2,9.9,54.3,10.3,54.3,10.8z  M53.6,10.8c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.3-0.4-0.4-0.6-0.4c-0.3,0-0.5,0.1-0.7,0.4c-0.1,0.2-0.2,0.5-0.2,0.8 c0,0.3,0.1,0.6,0.2,0.8c0.2,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.1,0.6-0.4C53.5,11.4,53.6,11.1,53.6,10.8z"
          />
          <path
            className="st2"
            d="M57.8,12.4h-0.6l-0.1-0.4h0c-0.2,0.3-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7 c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.8-0.3,1.4-0.3v-0.1c0-0.4-0.2-0.6-0.7-0.6c-0.3,0-0.6,0.1-0.8,0.2l-0.1-0.5 c0.3-0.2,0.7-0.3,1.1-0.3c0.8,0,1.3,0.4,1.3,1.3v1.2C57.8,12,57.8,12.3,57.8,12.4z M57.1,11.3v-0.5c-0.8,0-1.2,0.2-1.2,0.7 c0,0.2,0,0.3,0.1,0.4s0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4C57,11.4,57.1,11.4,57.1,11.3z"
          />
          <path
            className="st2"
            d="M61.9,12.4h-0.6l0-0.5h0c-0.2,0.4-0.6,0.6-1,0.6c-0.4,0-0.7-0.2-1-0.5c-0.3-0.3-0.4-0.7-0.4-1.2 c0-0.5,0.1-0.9,0.4-1.3c0.3-0.3,0.6-0.5,1-0.5c0.4,0,0.7,0.1,0.9,0.4h0V7.7h0.7v3.9C61.9,11.9,61.9,12.2,61.9,12.4z M61.1,11.1 v-0.5c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.2,0.5-0.2,0.8 c0,0.3,0.1,0.6,0.2,0.8c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3C61.1,11.5,61.1,11.3,61.1,11.1z"
          />
          <path
            className="st2"
            d="M68.1,10.8c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.1-0.5 c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-0.9,0.4-1.2s0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.1,0.5C67.9,9.9,68.1,10.3,68.1,10.8z  M67.3,10.8c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.3-0.4-0.4-0.6-0.4c-0.3,0-0.5,0.1-0.7,0.4c-0.1,0.2-0.2,0.5-0.2,0.8 c0,0.3,0.1,0.6,0.2,0.8c0.2,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.1,0.6-0.4C67.2,11.4,67.3,11.1,67.3,10.8z"
          />
          <path
            className="st2"
            d="M71.9,12.4h-0.7v-1.9c0-0.6-0.2-0.9-0.7-0.9c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.2-0.2,0.3-0.2,0.6v1.9h-0.7v-2.3 c0-0.3,0-0.6,0-0.9h0.6l0,0.5h0c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.6,0.4,1.1 V12.4z"
          />
          <path
            className="st2"
            d="M76.8,9.7H76v1.6c0,0.4,0.1,0.6,0.4,0.6c0.1,0,0.2,0,0.3,0l0,0.5c-0.1,0.1-0.3,0.1-0.6,0.1 c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.2-0.5-0.2-0.9V9.7h-0.5V9.2h0.5V8.6L76,8.4v0.8h0.8L76.8,9.7L76.8,9.7z"
          />
          <path
            className="st2"
            d="M80.6,12.4h-0.7v-1.8c0-0.6-0.2-0.9-0.7-0.9c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v1.9h-0.7V7.7h0.7v2h0 c0.2-0.4,0.6-0.5,1-0.5c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.4,0.6,0.4,1.1C80.6,10.5,80.6,12.4,80.6,12.4z"
          />
          <path
            className="st2"
            d="M84.5,10.7c0,0.1,0,0.2,0,0.3h-2.2c0,0.3,0.1,0.6,0.3,0.7c0.2,0.2,0.4,0.2,0.7,0.2c0.3,0,0.6-0.1,0.9-0.2 l0.1,0.5c-0.3,0.1-0.7,0.2-1.1,0.2c-0.5,0-0.9-0.1-1.2-0.4c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-0.9,0.4-1.2 c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.2,1.1,0.5C84.4,9.9,84.5,10.3,84.5,10.7z M83.8,10.5c0-0.2,0-0.4-0.1-0.6 c-0.1-0.2-0.3-0.3-0.6-0.3c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.2,0.3-0.2,0.6C82.3,10.5,83.8,10.5,83.8,10.5z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGComponent;
