import React from 'react'
import Header from "../Header/Header"
import UserPost from '../UserPost/UserPosts'

function UserPostes() {
    return (
        <div>
         <Header/>
         <UserPost/>        
        </div>
    )
}

export default UserPostes