import React from 'react'

import Header from '../Header/Header'

import Footer from '../Footer/Footer';
import PrivayPolicy from '../Policyes/PrivacyPolicy';

function PrivacyPolicyMake(props) {
    
    return (
        <div style={{backgroundColor: "#ebebeb"}}>
            <Header />
            <PrivayPolicy />
            <Footer />
        </div>
    )
}

export default PrivacyPolicyMake