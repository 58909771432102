import React, { useState, useEffect, useContext } from "react";
import '../Policyes/privacy.css';

function PrivayPolicy(){
    
    return (
        <>
          <div className="startprivacy">
          <h2>Privacy Policy</h2>
          <div>
        <p style={{marginTop: '0.5in', marginBottom: '8.0pt', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Arial",sans-serif'}}>This privacy notice for&nbsp;Drop Scrap&nbsp;('<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>'), describes how and why we might collect, store, use, and/or share ('<strong>process</strong>') your information when you use our services ('<strong>Services</strong>'), such as when you:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li style={{ marginBottom: '8.0pt', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Arial",sans-serif'}}>Visit our website at <a href="https://www.dropscrap.com/" target="_blank">https://www.dropscrap.com</a>, or any website of ours that links to this privacy notice</span></li>
          <li className="basicfunction"><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Arial",sans-serif'}}>Download and use&nbsp;our mobile application&nbsp;(Drop Scrap),&nbsp;or any other application of ours that links to this privacy notice</span></li>
          <li className="basicfunction"><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Arial",sans-serif'}}>Engage with us in other related ways, including any sales, marketing, or events</span></li>
        </ul>
        <p style={{ fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', lineHeight: 'normal'}}><strong><span style={{fontSize: '14px', fontFamily: '"Arial",sans-serif', color: '#595959'}}>&nbsp;</span></strong></p>
        <p style={{ fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', lineHeight: 'normal'}}><strong><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif', color: '#595959'}}>Questions or concerns?&nbsp;</span></strong><span style={{fontFamily: '"Arial",sans-serif', color: '#595959'}}>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.&nbsp;If you still have any questions or concerns, please contact us at&nbsp;help@dropscrap.com.</span></p>
        <p className="basicfunction">&nbsp;</p>
        <p className="basicfunction"><strong><span className="normalfunction">1: What personal information do we process?</span></strong><span className="normalfunction">&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.</span></p>
        <p className="basicfunction"><strong><em><span className="normalfunction">&nbsp;</span></em></strong><span className="normalfunction">We collect personal information that you provide to us.</span></p>
        <p className="basicfunction"><span className="normalfunction">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services when you participate in activities on the Services, or otherwise when you contact us.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">Personal Information Provided by You.</span></strong><span className="normalfunction">&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><span className="normalfunction">names</span></li>
          <li className="basicfunction"><span className="normalfunction">phone numbers</span></li>
          <li className="basicfunction"><span className="normalfunction">email addresses</span></li>
          <li className="basicfunction"><span className="normalfunction">mailing addresses</span></li>
          <li className="basicfunction"><span className="normalfunction">picture</span><span className="normalfunction">&nbsp;</span></li>
        </ul>
        <p className="basicfunction"><strong><span className="normalfunction">2</span></strong><span className="normalfunction">: <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong></span></p>
        <p className="basicfunction"><strong><span className="normalfunction">&nbsp;</span></strong><span className="normalfunction">We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></strong></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><strong><span className="normalfunction">To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</span></strong><span className="normalfunction">We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">To enable user-to-user communications.&nbsp;</span></strong><span className="normalfunction">We may process your information if you choose to use any of our offerings that allow for communication with another user.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">To save or protect an individual's vital interest.</span></strong><span className="normalfunction">&nbsp;We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></li>
        </ul>
        <p className="basicfunction"><strong><span className="normalfunction">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">If you are located in the EU or UK, this section applies to you.</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><strong><span className="normalfunction">Consent.&nbsp;</span></strong><span className="normalfunction">We may process your information if you have permitted us (i.e.&nbsp;consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. If we are relying on your consent to process your personal information,&nbsp;which may be express and/or implied consent depending on the applicable law,&nbsp;you have the right to withdraw your consent at any time.</span><span style={{fontSize: '14px', lineHeight: '107%', fontFamily: '"Arial",sans-serif', color: '#595959', background: 'white'}}>&nbsp;</span><span className="normalfunction">If you have questions or comments about this notice, you may email us at <strong>help@dropscrap.com</strong></span></li>
          <li className="basicfunction"><span className="normalfunction">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor when applicable law allows,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">Performance of a Contract.</span></strong><span className="normalfunction">&nbsp;We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request before entering into a contract with you.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">Legal Obligations.</span></strong><span className="normalfunction">&nbsp;We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.<br />&nbsp;</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">Vital Interests.</span></strong><span className="normalfunction">&nbsp;We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as in situations involving potential threats to the safety of any person.</span></li>
        </ul>
        <p style={{ marginBottom: '8.0pt', marginLeft: '.5in', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span className="normalfunction">&nbsp;</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">We may share information in specific situations described in this section and/or with the following third parties.</span></p>
        <p className="basicfunction"><span className="normalfunction">We&nbsp;may need to share your personal information in the following situations:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><strong><span className="normalfunction">Business Transfers.</span></strong><span className="normalfunction">&nbsp;We may share or transfer your information in connection with or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">When we use Google Analytics.</span></strong><span className="normalfunction">&nbsp;We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use include Google Display Network Impressions Reporting and Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>. You can opt out of Google Analytics Advertising Features through <a href="https://adssettings.google.com/" target="_blank">Ads Settings</a> and Ad Settings for mobile apps. Other opt-out means include <a href="http://optout.networkadvertising.org/" target="_blank">http://optout.networkadvertising.org/</a> and <a href="http://www.networkadvertising.org/mobile-choice" target="_blank">http://www.networkadvertising.org/mobile-choice</a>. For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy" target="_blank">Google Privacy &amp; Terms page</a>.</span></li>
          <li className="basicfunction"><strong><span className="normalfunction">Other Users.</span></strong><span className="normalfunction">&nbsp;When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</span></li>
        </ul>
        <p className="basicfunction"><strong><span className="normalfunction">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</span></p>
        <p className="basicfunction"><span className="normalfunction">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span></p>
        <p className="basicfunction"><span className="normalfunction">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">6. IS YOUR INFORMATION LIKE ADS YOU POSTED TRANSFERRED INTERNATIONALLY?</span></strong></p>
        <p className="basicfunction"><strong><span className="normalfunction">&nbsp;</span></strong><span className="normalfunction">We may transfer, store, and process your information in countries other than your own.</span></p>
        <p className="basicfunction"><span className="normalfunction">Our servers are located in&nbsp;the&nbsp;United States, and&nbsp;Australia. If you are accessing our Services from outside&nbsp;the&nbsp;United States, and&nbsp;Australia, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information in&nbsp;the&nbsp;United States,&nbsp;Australia,&nbsp;United Arab Emirates,&nbsp;Pakistan,&nbsp;and other countries.</span></p>
        <p className="basicfunction"><span className="normalfunction">If you are a resident of the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information under this privacy notice and applicable law.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">We keep your information for as long as necessary to&nbsp;fulfil&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</span></p>
        <p className="basicfunction"><span className="normalfunction">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us to keep your personal information for longer than&nbsp;the period in which users have an account with us.</span></p>
        <p className="basicfunction"><span className="normalfunction">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">We aim to protect your personal information through a system of organizational and technical security measures.</span></p>
        <p className="basicfunction"><span className="normalfunction">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">9. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</span></p>
        <p className="basicfunction"><span className="normalfunction">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under the age of 18, please contact us at help@dropscrap.com.</span></p>
        <p className="basicfunction"><strong><span className="normalfunction">10. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></p>
        <p className="basicfunction"><span className="normalfunction">If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><span className="normalfunction">Log in to your account settings and update your user account.</span></li>
        </ul>
        <p style={{ marginBottom: '8.0pt', marginLeft: '.25in', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><strong><span className="normalfunction">Delete Account</span></strong></p>
        
        <p style={{ marginBottom: '8.0pt', marginLeft: '.25in', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span className="normalfunction">To permanently delete your account on the website, navigate to your profile, go to settings, and click on the "Delete Account" button. If you're using the mobile app, go to your profile, select "Help and Contact," and then choose the "Delete Account Permanently" option. This action will remove all of your data. If you need assistance, visit your profile, tap on "Help and Contact," and a page will open where you can submit your request. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p>
        <p className="basicfunction"><span className="normalfunction">If you would at any time like to review or change the Post information in your account or terminate your post, you can:</span></p>
        <ul style={{ marginTop: '0in'}} type="square">
          <li className="basicfunction"><span className="normalfunction">Log in and then go to Your post.</span></li>
        </ul>
        <p className="basicfunction"><span className="normalfunction">If you have questions or comments about your privacy rights, you may email us at&nbsp;help@dropscrap.com.</span></p>
        <p className="basicfunction"><span className="normalfunction">&nbsp;</span></p>
      </div>
          </div>
        </>
      );
}

export default  PrivayPolicy