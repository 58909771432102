import React,{useContext} from 'react'
import {useNavigate} from "react-router-dom";
import {PostContext} from "../../Auth/PostContext";
import "./card3.css"
import Options from '../PostCardScrap/Options';
function PostCards3({product,index}) {
    let {setPostContent} = useContext(PostContext)
    var ago=timeSince(new Date(product.time.toDate()));
    const navigate=useNavigate()
  
    function timeSince(date) {

      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    }

    return (
      <div className="pointers" key={index} onClick={()=>{
        setPostContent(product)
        // navigate(`/viewpost/${product.id}`)
      }}>
        <div className="mainetering">
        <div className="image">
          <img src={product.url[0]} alt="" />
        </div>
        <div className="content">
          <div className="name"> {product.name}</div>
          <div className="rate"> {product.description}</div>
          <div className="addresscountry">
          <div className="category">{product.countryunit} {product.price} </div>
          </div>
          <div className="date">{ago}</div>
        </div>
        <div className="yes">
        <Options
              productid={product?.id}
              uid={product?.userId}
              fileurl={product?.url}
            />
        </div>
        </div>
        
       
       
        
      </div>
       
    )
}

export default PostCards3