import { TextField } from '@mui/material';

const NameField = ({ nameRef, defaultValue = '' }) => {
  return (
    <TextField
      autoFocus
      margin="normal"
      variant="standard"
      id="Name"
      label="Enter Name"
      type="name"
      fullWidth
      required
      inputRef={nameRef}
      defaultValue={defaultValue}
      InputLabelProps={{
        style: { color: '#777' },
      }}
    />
  );
};

export default NameField;