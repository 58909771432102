import React from 'react'

import Header from '../Header/Header'

import Footer from '../Footer/Footer';
import About from '../Policyes/about';

function AboutMake(props) {
    
    return (
        <div style={{backgroundColor: "#ebebeb"}}>
            <Header />
            <About />
            <Footer />
        </div>
    )
}

export default AboutMake