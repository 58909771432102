import React, { useState } from "react";
import { Firebase } from "../../firebase/config";
import { useAuth } from '../../Auth/AuthContext copy';
import { useNavigate } from "react-router";
function ContactForm() {
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const { currentUser,setLoading} = useAuth();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Firebase.firestore().collection("Support").doc().set({
      "Email": email,
      "DescribeIssue": issue,
      "id":currentUser.uid,
      "Solve": false,
    }).then(() => {
      setLoading(false);
      navigate("/");
    });;
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>How can you contact Us?</h2>
      <p style={styles.subHeader}>
        At DropScrap, we believe in providing smooth service to our customers.
        You can contact us through the following platforms:
      </p>
      <div style={styles.socialLinks}>
        <p style={styles.contactText}>
          1. Contact us on Twitter:{" "}
          <a
            href="https://twitter.com/dropscraps"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            @dropscraps
          </a>
        </p>
        <p style={styles.contactText}>
          2. Contact us on Facebook:{" "}
          <a
            href="https://web.facebook.com/people/Drop-Scrap/61558798318333/?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Drop Scrap Facebook Page
          </a>
        </p>
        <p style={styles.contactText}>
          3. Contact us on Instagram:{" "}
          <a
            href="https://www.instagram.com/dropscrap?igsh=ejRlbWI3enN3NXc5"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            @dropscrap
          </a>
        </p>
      </div>
      <p style={styles.subHeader}>
        If you have any issues or concerns, please fill out the form below, and
        our team will resolve your issue as soon as possible. You can also reach
        us at <a href="mailto:help@dropscrap.com" style={styles.link}>help@dropscrap.com</a>.
      </p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="issue">Issue</label>
          <textarea
            id="issue"
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            required
            style={styles.textarea}
          ></textarea>
        </div>
        <button type="submit" style={styles.submitButton}>
          Submit
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "var(--regularfont)",
  },
  title: {
    fontFamily: "var(--boldfont)",
    fontSize: "2em",
    fontWeight: 600,
    margin: "0.67em 0",
    textAlign: "center",
  },
  subHeader: {
    fontFamily: "var(--regularfont)",
    marginBottom: "20px",
    color: "#000", // Black text
  },
  socialLinks: {
    marginBottom: "20px",
  },
  contactText: {
    fontFamily: "var(--regularfont)",
    color: "#000", // Black text
    marginBottom: "10px",
  },
  link: {
    color: "#28a745", // Green link color
    textDecoration: "none",
  },
  form: {
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
    minHeight: "100px",
  },
  submitButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default ContactForm;
