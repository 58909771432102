import '../Policyes/privacy.css';

function About(){
    
    return (
        <>
          <div className="startprivacy">
          <h2>About</h2>
          <div>
          <p style={{marginTop: '0.3in', marginRight: '0in', marginBottom: '8.0pt',  fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><strong><span className="normalfunction">Motive of DropScrap</span></strong></p>
          <p className="basicfunction"><span className="normalfunction"> By providing a user-friendly platform accessible with just one click, Drop Scrap aims to empower individuals to easily list their scrap materials and connect with potential buyers. Unlike traditional platforms, Drop Scrap operates on a consumer-to-consumer (C2C) model, cutting out intermediaries and allowing users to interact directly. This model promotes a sense of community and trust among users, as they are dealing with fellow individuals rather than large corporations. The platform hosts a diverse range of scrap materials, including metals like aluminum, copper, and steel, plastics, paper, glass, electronics, and more. This wide variety ensures that users can find the specific materials they need or offload excess materials they no longer require, contributing to a more sustainable and circular economy.</span></p>
          <p className="basicfunction"><span className="normalfunction">Ultimately, the platform's goal is to make the process of selling scrap materials as simple and straightforward as possible, benefiting both sellers and buyers alike.</span></p>
          <p className="basicfunction"><span className="normalfunction">&nbsp;</span></p>
          </div>
          </div>
        </>
      );
}

export default  About