import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useAuth } from '../../Auth/AuthContext copy';
import EmailField from './inputs/EmailField';
import NameField from './inputs/NameField';
import PasswordField from './inputs/PasswordField';
import SubmitButton from './inputs/SubmitButton';
import ResetPassword from './ResetPassword';
import "./Login.css";
import { Google } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';




const Loogin = () =>{
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [isRegister, setIsRegister] = useState(false);
  const {
    modal,
    setModal,
    signUp,
    login,
    loginWithGoogle,
    setAlert,
    setLoading,
  } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    if (isRegister) {
      const confirmPassword = confirmPasswordRef.current.value;
      const username=nameRef.current.value
      try {
        if (password !== confirmPassword) {
          throw new Error("Passwords don't match");
        }
        await signUp(email, password,username);
        setModal({ ...modal, isOpen: false });
      } catch (error) {
        setAlert({
          isAlert: true,
          severity: 'error',
          message: error.message,
          timeout: 5000,
          location: 'modal',
        });
        console.log(error);
      }
    } else {
      try {
        await login(email, password);
        setModal({ ...modal, isOpen: false });
      } catch (error) {
        setAlert({
          isAlert: true,
          severity: 'error',
          message: error.message,
          timeout: 5000,
          location: 'modal',
        });
        console.log(error);
      }
    }
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      setModal({ ...modal, isOpen: false });
    } catch (error) {
      setAlert({
        isAlert: true,
        severity: 'error',
        message: error.message,
        timeout: 5000,
        location: 'modal',
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (isRegister) {
      setModal({ ...modal, title: 'Register' });
    } else {
      setModal({ ...modal, title: 'Login' });
    }
  }, [isRegister]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <DialogContentText>
          {isRegister
          ? 'Complete your Details'
          : "Login with your Email Password"}
            
          </DialogContentText>
          {isRegister && (
            <NameField  {...{ nameRef }} />
          )}
          <EmailField {...{ emailRef }} />
          <PasswordField {...{ passwordRef, autoFocus: false }} />
          {isRegister && (
            <PasswordField
              {...{
                passwordRef: confirmPasswordRef,
                id: 'confirmPassword',
                label: 'Confirm Password',
                autoFocus: false,
              }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: '19px' }}>
          <Button
            size="small"
            style={{ color: 'green'}}
            onClick={() =>
              setModal({
                ...modal,
                title: 'Reset Password',
                content: <ResetPassword />,
              })
            }
          >
            Forgot Password?
          </Button>
          <SubmitButton />
        </DialogActions>
      </form>
      <DialogActions sx={{ justifyContent: 'left', p: '5px 24px' }}>
        {isRegister
          ? 'Do you have an account? Login in now'
          : "Don't you have an account? Create one now"}
        <Button style={{ color: 'green'}} onClick={() => setIsRegister(!isRegister)}>
          {isRegister ? 'Login' : 'Register'}
        </Button>
      </DialogActions>
      <DialogActions sx={{justifyContent: 'center', py: '24px' }}>
        <Button
          style={{ color: 'green', borderColor: 'green' }}
          variant="outlined"
          startIcon={<Google />}
          onClick={handleGoogleLogin}
        >
          Login with Google
        </Button>
      </DialogActions>
    </>
  );
}

export default Loogin;
