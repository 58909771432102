import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router";
import { Firebase } from "../../firebase/config";
import PostCards from "../PostCardScrap/PostCards";
import CircleLoading from "../LoadingsPages/CircleLoading";
import "../ViewMorePostsScrap/allfilterpostees.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from "../PostScrap/Post";
function SearchPostdetail() {
  const [posts, setPosts] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [nextloading, setNextLoading] = useState(true);
  const { searchword } = useParams();
  console.log(searchword)
  // const stateParmvalu=useLocation().state;
  // console.log("ywwe"+searchword);
  // console.log(stateParmvalu);
  
  useEffect(() => {
    
      try{
        //let lastKey = "";
        const searchpost=[];
        console.log("enter")
        Firebase.firestore()
        .collection("products").orderBy("time", "desc").limit(50)
        .get().then((snapshot) => {
         let filterpost = snapshot.docs.map((product) => {
           
          if(product.data().name.toLowerCase().includes(searchword)){
            const idd=product.id;
            //lastKey = product.data().createdAt;
            const don=
             {
                id: idd,
                 ...product.data(),
               }
            searchpost.push(don)
          }
          
         }
         );
         const lastKey=snapshot.docs[snapshot.docs.length-1];
         setLastKey(lastKey)
         setPosts(searchpost)
       });;
      }catch{

      }
   
  }, [searchword]);

  /**
  * used to apply pagination on posts
  * @param {String} key
  * @return next batch of posts (+5 posts)
  * will be fired when user click on 'More Posts' button.
  */
  const fetchMorePosts = (key) => {
      
        try {
          //let lastKey = "";
          const searchpostafter=[];
          Firebase.firestore()
        .collection("products").orderBy("time", "desc").startAfter(key).limit(50)
        .get()
        .then((snapshot) => {
          let filterpost = snapshot.docs.map((product) => {

            if(product.data().name.toLowerCase().includes(searchword)){
              const idd=product.id;
              //lastKey = product.data().createdAt;
              const don=
               {
                  id: idd,
                   ...product.data(),
                 }
              searchpostafter.push(don)
            }
            
          }
          );
          const lastKey=snapshot.docs[snapshot.docs.length-1];
          setPosts(posts.concat(searchpostafter));
          setLastKey(lastKey);
          console.log("last key "+lastKey)
          if(lastKey===undefined){
            setNextLoading(false)
          }
          
        });
    
        }
         catch (e) {
          console.log(e);
        }

  };

  let allPosts = (
    // type == "all" ? 
    posts.map((product, index) => {
      return (
        <div className="cardfilter" key={index}>
          {" "}
          <PostCards product={product} index={index} />{" "}
        </div>
      );
    })
  );

  return (
    <>
     
      <div className="filter-posts">
        <div className="allpostbox">
          <InfiniteScroll
            dataLength={allPosts.length} //This is important field to render the next data
            next={() => fetchMorePosts(lastKey)}
            hasMore={true}
            loader={
              nextloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/></div> :<div   style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            > End Post</div>
          }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className="filterpost-container">
              {allPosts}

            </div>
          </InfiniteScroll>
          

        </div>
       
      </div>

    </>
  );
}

export default SearchPostdetail;