import React, { useState, useEffect, useContext } from "react";
import { Link,useParams,useLocation } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import "./HomePagePost.css";
import { Firebase } from "../../firebase/config";
import CircleLoading from "../LoadingsPages/CircleLoading";
import PostCards from "../PostCardScrap/PostCards";


function HomePost() {
 
  let [plastic, setPlastic] = useState([]);
  let [metal, setmetal] = useState([]);
  let [aluminum, setaluminum] = useState([]);
  let [copper, setcopper] = useState([]);
  let [all, setall] = useState([]);
  let [other, setothers] = useState([]);
  let [steel, setsteel] = useState([]);
  const [plasticlastKey, setplasticLastKey] = useState("");
  const [aluminumlastKey, setaluminumLastKey] = useState("");
  const [copperlastKey, setcopperLastKey] = useState("");
  const [otherlastKey, setotherLastKey] = useState("");
  const [steellastKey, setsteelLastKey] = useState("");
  const [metallastKey, setmetalLastKey] = useState("");
  const [alllastKey, setallLastKey] = useState("");
  const[allloading,setallloading]=useState(true);
  const[plasticloading,setplasticloading]=useState(true);
  const[metalloading,setmetalloading]=useState(true);
  const[copperloading,setcopperloading]=useState(true);
  const[steelloading,setsteelloading]=useState(true);
  const[aluminumloading,setaluminumloading]=useState(true);
  const[otherloading,setotherloading]=useState(true);

  useEffect(() => {
   
    console.log("DONE")
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products")
    .orderBy("time", "desc").limit(8)
    .get()
    .then(
      (snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setall(filterpost);
      setallLastKey(lastKey)
   
    }
    );
    }
     catch (e) {
      console.log(e);
    }

    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Plastic")
    .orderBy("time", "desc").limit(8)
    .get()
    .then(
      (snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setPlastic(filterpost);
      setplasticLastKey(lastKey)
   
    }
    );

    }
     catch (e) {
      console.log(e);
    }
    

    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Metal")
    .orderBy("time", "desc").limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setmetal(filterpost);
      setmetalLastKey(lastKey)
   
    });

    }
     catch (e) {
      console.log(e);
    }


    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Steel")
    .orderBy("time", "desc").limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setsteel(filterpost);
      setsteelLastKey(lastKey)
   
    });

    }
     catch (e) {
      console.log(e);
    }


    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Copper")
    .orderBy("time", "desc").limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setcopper(filterpost);
      setcopperLastKey(lastKey)
   
    });

    }
     catch (e) {
      console.log(e);
    }


    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Aluminium")
    .orderBy("time", "desc").limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setaluminum(filterpost);
      setaluminumLastKey(lastKey)
   
    });

    }
     catch (e) {
      console.log(e);
    }


    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Others")
    .orderBy("time", "desc").limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),
          
        };
      });
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setothers(filterpost);
      setotherLastKey(lastKey)
   
    });

    }
     catch (e) {
      console.log(e);
    }

  }, []);

  const fetchMoreplasticPosts = (key) => {
  
        try {
          //let lastKey = "";
          Firebase.firestore() //retreving all posts from firebase in asecnding order of date
        .collection("products").where("category","==","Plastic")
        .orderBy("time", "desc").startAfter(key).limit(8)
        .get()
        .then((snapshot) => {
          let filterpost = snapshot.docs.map((product) => {
            const idd=product.id;
            //lastKey = product.data().createdAt;
            return {
                id: idd,
              ...product.data(),  
            };
            
          }
          );
          const lastKey=snapshot.docs[snapshot.docs.length-1];
          setplasticLastKey(lastKey);
          setPlastic(plastic.concat(filterpost));
          if(lastKey===undefined){
             setplasticloading(false)
           }
        }
        );
    
        }
         catch (e) {
          console.log(e);
        }
      
  };
  
  const fetchMoremetalPosts = (key) => {
    
      try {
        //let lastKey = "";
        Firebase.firestore() //retreving all posts from firebase in asecnding order of date
      .collection("products").where("category","==","Metal")
      .orderBy("time", "desc").startAfter(key).limit(8)
      .get()
      .then((snapshot) => {
        let filterpost = snapshot.docs.map((product) => {
          const idd=product.id;
          //lastKey = product.data().createdAt;
          return {
              id: idd,
            ...product.data(),  
          };
          
        }
        );
        const lastKey=snapshot.docs[snapshot.docs.length-1];
        setmetalLastKey(lastKey);
        setmetal(metal.concat(filterpost));
        if(lastKey===undefined){
          setmetalloading(false)
        }
      });
  
      }
       catch (e) {
        console.log(e);
      }
    
};
const fetchMoreSteelPosts = (key) => {
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Steel")
    .orderBy("time", "desc").startAfter(key).limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),  
        };
        
      }
      );
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setsteelLastKey(lastKey);
      setsteel(steel.concat(filterpost));
      if(lastKey===undefined){
        setsteelloading(false)
      }
    });
    }
     catch (e) {
      console.log(e);
    }
  
};
const fetchMoreCopperPosts = (key) => {
  
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Copper")
    .orderBy("time", "desc").startAfter(key).limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),  
        };
        
      }
      );
      const lastKey=snapshot.docs[snapshot.docs.length-1]; 
      setcopperLastKey(lastKey);
      setcopper(copper.concat(filterpost));
      if(lastKey===undefined){
        setcopperloading(false)
      }
    });

    }
     catch (e) {
      console.log(e);
    }
  
};
const fetchMoreAluminiumPosts = (key) => {
  
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Aluminium")
    .orderBy("time", "desc").startAfter(key).limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),  
        };
        
      }
      );
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setaluminumLastKey(lastKey);
      console.log("last key "+lastKey)
      setaluminum(aluminum.concat(filterpost));
      if(lastKey===undefined){
        setaluminumloading(false)
      }
    });

    }
     catch (e) {
      console.log(e);
    }
  
};
const fetchMoreOthersPosts = (key) => {
  
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products").where("category","==","Others")
    .orderBy("time", "desc").startAfter(key).limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),  
        };
        
      }
      );
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setotherLastKey(lastKey);
      setothers(other.concat(filterpost));
      if(lastKey===undefined){
        setotherloading(false)
      }

    });

    }
     catch (e) {
      console.log(e);
    }
  
};
const fetchallPosts = (key) => {
    try {
      //let lastKey = "";
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
    .collection("products")
    .orderBy("time", "desc").startAfter(key).limit(8)
    .get()
    .then((snapshot) => {
      let filterpost = snapshot.docs.map((product) => {
        const idd=product.id;
        //lastKey = product.data().createdAt;
        return {
            id: idd,
          ...product.data(),  
        };
        
      }
      );
      const lastKey=snapshot.docs[snapshot.docs.length-1];
      setallLastKey(lastKey);
      // console.log(lastKey)
      setall(all.concat(filterpost));
      if(lastKey===undefined){
        setallloading(false)
      }
    }
    );

    }
     catch (e) {
      console.log(e);
    }
  
};

  let allplasticPosts = (
  
    plastic.map((product, index) => {
      return (
        <div className="postescards" key={index}>
          {" "}
          <PostCards product={product} index={index} />{" "}
        </div>
      );
    })
  );

  let allmetalPosts = (
  
    metal.map((product, index) => {
      return (
        <div className="postescards" key={index}>
        {" "}
        <PostCards product={product} index={index} />{" "}
      </div>
      );
    })
  );

  let allaluminumPosts = (
  
    aluminum.map((product, index) => {
      return (
        <div className="postescards" key={index}>
        {" "}
        <PostCards product={product} index={index} />{" "}
      </div>
      );
    })
  );

  let allsteelPosts = (
  
    steel.map((product, index) => {
      return (
        <div className="postescards" key={index}>
          {" "}
          <PostCards product={product} index={index} />{" "}
        </div>
      );
    })
  );

  let allcopperPosts = (
  
    copper.map((product, index) => {
      return (
        <div className="postescards" key={index}>
        {" "}
        <PostCards product={product} index={index} />{" "}
      </div>
      );
    })
  );

  let allotherPosts = (
  
    other.map((product, index) => {
      return (
        <div className="postescards" key={index}>
        {" "}
        <PostCards product={product} index={index} />{" "}
      </div>
      );
    })
  );

  let allPosts = (
  
    all.map((product, index) => {
      return (
        <div className="postescards" key={index}>
          {" "}
          <PostCards product={product} index={index} />{" "}
        </div>
      );
    })
  );





//   //quickMenuCards assign all cards of post item later it will be displayed
//   let quickMenuCards = posts.map((product, index) => {
//     return(<div className="quick-menu-cards" key={index}> <PostCards product={product} index={index} /> </div>);
//   });

//   let MetalProduct=posts.filter((itm)=>itm.category==="Plastic").map((product,index)=>{
//     if(index<10){
//       return(
//         <PostCards product={product} index={index} key={index} />
//       )
//     }
//     return null 
// });

  return (
    <div className="HeadDiv">
        <div className="Viewmore">
          <div className="bolding">
            <span>All</span>
            <Link to={{
              pathname:"/viewmore/all",
              // state:{typedata:"Plastic"}
            }}>
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
         <div className="cardes"   
            id="scrollableDiv1"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allPosts.length} //This is important field to render the next data
            next={() => fetchallPosts(alllastKey)}
            hasMore={true}
            loader={
              allloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv1"
          >
            <div className="allpostdone"> {allPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
      
      <div>
      
        <div className="Viewmore">
          <div className="bolding">
            <span>Plastic Product</span>
            <Link to=
                {{pathname:"/viewmore/Plastic"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv2"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allplasticPosts.length} //This is important field to render the next data
            next={() => fetchMoreplasticPosts(plasticlastKey)}
            hasMore={true}
            loader={
              plasticloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv2"
          >
            <div className="allpostdone"> {allplasticPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
     
      </div>
      <div>
      
        <div className="Viewmore">
          <div className="bolding">
            <span>Metal Product</span>
            <Link to=
                {{pathname:"/viewmore/Metal"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv3"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allmetalPosts.length} //This is important field to render the next data
            next={() => fetchMoremetalPosts(metallastKey)}
            hasMore={true}
            loader={
              metalloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv3"
          >
            <div className="allpostdone"> {allmetalPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
    
      </div>
      <div>
      
        <div className="Viewmore">
          <div className="bolding">
            <span>Copper Product</span>
            <Link to=
                {{pathname:"/viewmore/Copper"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv4"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allcopperPosts.length} //This is important field to render the next data
            next={() => fetchMoreCopperPosts(copperlastKey)}
            hasMore={true}
            loader={
              copperloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv4"
          >
            <div className="allpostdone"> {allcopperPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>

      </div>
      <div>
      
        <div className="Viewmore">
          <div className="bolding">
            <span>Steel Product</span>
            <Link to=
                {{pathname:"/viewmore/Steel"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv5"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allsteelPosts.length} //This is important field to render the next data
            next={() => fetchMoreSteelPosts(steellastKey)}
            hasMore={true}
            loader={
              steelloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv5"
          >
            <div className="allpostdone"> {allsteelPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
      </div>
      <div>
        <div className="Viewmore">
          <div className="bolding">
            <span>Aluminium Product</span>
            <Link to=
                {{pathname:"/viewmore/Aluminium"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv6"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allaluminumPosts.length} //This is important field to render the next data
            next={() => fetchMoreAluminiumPosts(aluminumlastKey)}
            hasMore={true}
            loader={
              aluminumloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv6"
          >
            <div className="allpostdone"> {allaluminumPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
      </div>
      <div>
        <div className="Viewmore">
          <div className="bolding">
            <span>Other</span>
            <Link to=
                {{pathname:"/viewmore/Others"}}
            >
              {" "}
              <span>View more</span>{" "}
            </Link>
          </div>
          <div className="cardes"   
            id="scrollableDiv7"
            style={{
              overflowX:'hidden',
              overflowY:'scroll',
              flexDirection: 'column',
              marginBottom:'20px'
            }}
          >
            <InfiniteScroll
            style={{    flexDirection: 'column'}}
            dataLength={allotherPosts.length} //This is important field to render the next data
            next={() => fetchMoreOthersPosts(otherlastKey)}
            hasMore={true}
            loader={
              otherloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/>
            </div>:
            <div
            style={{
              display: 'flex',
              justifyContent: 'center', 
            }}>Post End</div>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv7"
          >
            <div className="allpostdone"> {allotherPosts} </div>
             
          </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePost;
