import React from 'react'
import {BrowserRouter as Router , Route, Routes,} from 'react-router-dom'
import HomeScreen from '../Startpage/HomePage'
import Login from '../Startpage/LoginPagess'
import CreateScrap from '../Startpage/EstablishingPost'
import ViewScrap from '../Startpage/ViewingPage'
import ViewMorePosts from '../Startpage/ViewAllMorePage'
import SearchPostComplete from '../Startpage/Viewsearchpost'
import UserPostes from '../Startpage/UserPos'
import PrivacyPolicyMake from '../Startpage/PrivatcyPolicy'
import TermConditionMake from '../Startpage/TermCondition'
import AboutMake from '../Startpage/Aboutpage'
import ContactsScreen from '../Startpage/contact_help'



function Routers() {
    return (

       <Router>
        <Routes>
           <Route path="/" element={<HomeScreen />} />
           <Route path="/login" element={<Login />} />
           <Route path="/createpost" element={<CreateScrap />} />
           <Route path="/privacy" element={<PrivacyPolicyMake />} />
           <Route path="/about" element={<AboutMake />} />
           <Route path="/term" element={<TermConditionMake />} />
           <Route path="/contact" element={<ContactsScreen />} />
           <Route path="/viewpost/:productid" element={<ViewScrap />} />
           <Route path="/viewmore/:type" element={<ViewMorePosts />} />
           <Route path="/search/:searchword" element={<SearchPostComplete />} />
           <Route path="/userpost/:id" element={<UserPostes />} />
        </Routes>   
       </Router>
    )
}

export default Routers
